import React from "react";
import GeosAddEditForm from "./GeosAddEditForm";
import Breadcrumbs from "./Breadcrumbs";

const GeosAdd = () => {
  return (
    <>
      <Breadcrumbs
        crumbs={{
          Geos: "/geos"
        }}
        title="New Geo"
      />
      <GeosAddEditForm />
    </>
  );
};

export default GeosAdd;
