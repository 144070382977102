import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import ChannelsAddEdit from "./ChannelsAddEdit";

const ChannelsEdit = props => {
  return (
    <>
      <Breadcrumbs
        crumbs={{
          Channels: "/channels"
        }}
        title="Edit Channels"
      />
      <ChannelsAddEdit channelId={props.channelId} />
    </>
  );
};

export default ChannelsEdit;
