import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import Breadcrumbs from "./Breadcrumbs";
import { reactFormatter, ReactTabulator } from "react-tabulator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { api_request } from "./Helpers";
import ContentLoader from "./ContentLoader";
import { ActionsFormatter } from "./Helpers/TableFormatters";

const Channels = props => {
  const columns = [
    { title: "Channels", field: "channel", headerFilter: "input" },
    {
      title: "",
      field: "actions",
      formatter: reactFormatter(
        <ActionsFormatter section="channels" editOnly={true} />
      ),
      headerSort: false
    }
  ];

  const options = {
    layout: "fitColumns",
    pagination: "local",
    paginationSize: 20
  };

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    api_request("/admin/channels", "GET").then(res => {
      const channels = res.data.channels;
      let channelsData = [];

      if (channels.length === 0) {
        setTableData(null);
        return;
      }

      for (let key in channels) {
        const channel = channels[key];

        channelsData.push({
          id: channel.id,
          channel: channel.channel
        });
      }
      setTableData(channelsData);
    });
  }, []);

  return (
    <>
      <Breadcrumbs title="Channels" />
      {props.location.state && props.location.state.newChannelId ? (
        <div className="notification is-info">New channel added!</div>
      ) : null}
      {props.location.state && props.location.state.editChannelId ? (
        <div className="notification is-info">
          Channel has been successfully edited!
        </div>
      ) : null}
      <div className="buttons has-addons is-right">
        <Link to="add">
          <button className="button is-primary">
            <span className="icon is-small">
              <FontAwesomeIcon icon={faPlus} />
            </span>
            <span> Add Channel</span>
          </button>
        </Link>
      </div>
      {tableData === null ? (
        "No Channels available."
      ) : tableData.length > 0 ? (
        <ReactTabulator
          data={tableData}
          columns={columns}
          tooltips={true}
          options={options}
        />
      ) : (
        <ContentLoader />
      )}
    </>
  );
};

export default Channels;
