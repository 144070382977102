import React, { useEffect, useState } from "react";
import { api_request } from "./Helpers";
import FormValidationMessage from "./FormValidationMessage";
import { errRequired } from "./Helpers/FormValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "@reach/router";

const ChannelsAddEdit = props => {
  const [isNameDisabled, setIsNameDisabled] = useState(false);

  const [channelName, updateChannelName] = useState("");

  const [isFormSubmitted, setIsFormSubmitted] = useState(null);
  const [channelNameValidatonMsg, setChannelNameValidatonMsg] = useState(null);

  // The array of channel batch ids.
  const [channelBatchIDList, updateChannelBatchIDList] = useState([]);
  // A new channel batch id.
  const [channelBatchIDNew, updateChannelBatchIDNew] = useState("");

  // For any errors returned by the backend.
  const [backendError, setBackendError] = useState(null);

  useEffect(() => {
    if (props.channelId) {
      setIsNameDisabled(true);

      api_request("/admin/channels/".concat(props.channelId), "GET").then(
        res => {
          const channel = res.data.channel;

          updateChannelName(channel.channel);

          // Populate the Batch CSV Ids for channels.
          let batchCSVID = [];
          if (channel.batch_csv_id) {
            Object.keys(channel.batch_csv_id).forEach(function(key) {
              batchCSVID.push(channel.batch_csv_id[key]);
            });
          }
          updateChannelBatchIDList(batchCSVID);
        }
      );
    }
  }, []);

  function handleChannelBatchIdDelete(selectedValue) {
    // Removed items from the list.
    updateChannelBatchIDList(
      channelBatchIDList.filter(e => e !== selectedValue)
    );
  }

  function addChannelBatchCSVID() {
    // Adds items to the list.

    if (channelBatchIDList.includes(channelBatchIDNew)) {
      // Don't add duplicates.
      updateChannelBatchIDNew("");
      return;
    }

    // Create a copy of the existing list and add the new value in.
    let updatedList = channelBatchIDList;
    updatedList.push(channelBatchIDNew);

    updateChannelBatchIDList(updatedList);
    updateChannelBatchIDNew("");
  }

  function submit() {
    // Form submission.
    setIsFormSubmitted(true);

    let failedValidation = false;

    setChannelNameValidatonMsg(null);

    if (channelName === "") {
      failedValidation = true;
      setChannelNameValidatonMsg(errRequired);
    }

    if (failedValidation) {
      setIsFormSubmitted(false);
      return;
    }

    let params = {
      channel_name: channelName,
      batch_csv_id: JSON.stringify(channelBatchIDList)
    };

    if (props.channelId) {
      params.channel_id = props.channelId;
    }

    api_request("/admin/channels", "POST", params).then(res => {
      let data = res.data;
      if ("error" in data) {
        setIsFormSubmitted(false);
        setBackendError(data.error);
        return;
      }

      if (props.channelId) {
        navigate("/channels", {
          state: { editChannelId: data["channel_id"] }
        });
      } else {
        navigate("/channels", {
          state: { newChannelId: data["channel_id"] }
        });
      }
    });
  }

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input
                  className="input"
                  disabled={isNameDisabled}
                  value={channelName}
                  onChange={e => updateChannelName(e.target.value)}
                />
                <FormValidationMessage message={channelNameValidatonMsg} />
              </p>
              <p className="help">
                The name for this channel. <b>Note:</b> Names will be
                automatically converted to lowercase and underscores will be
                added.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Batch CSV Identifiers</label>
            </div>
            <div className="field-body">
              <div className="field has-addons">
                <div className="control">
                  <input
                    className="input"
                    placeholder=""
                    value={channelBatchIDNew}
                    onChange={e => updateChannelBatchIDNew(e.target.value)}
                  />
                </div>
                <div className="control">
                  <a className="button is-info" onClick={addChannelBatchCSVID}>
                    Add
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label"></label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="help">
                  These are the strings that identify a channel in batch files.
                  A batch will fail without at least 1 of these.
                  <b>Note:</b> The system automatically converts these strings
                  to lower case on the database.
                </p>
                <ul style={{ marginBottom: "10px" }}>
                  {channelBatchIDList.map(val => {
                    return (
                      <li key={val}>
                        <span className="is-inline">- {val} </span>
                        <span
                          className="icon is-small is-inline pointer red"
                          onClick={() => handleChannelBatchIdDelete(val)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label" />
          <div className="field-body">
            <div className="field">
              <FormValidationMessage message={backendError} />
              <div className="control">
                <button
                  className={
                    "button is-primary " + (isFormSubmitted ? "is-loading" : "")
                  }
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                  <span>
                    {props.channelId ? "Edit Channel" : "New Channel"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ChannelsAddEdit;
