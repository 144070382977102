import React, { useEffect, useState } from "react";
import Campaign from "./Campaign";
import CampaignTabs from "./CampaignTabs";
import { api_request } from "./Helpers";
import ContentLoader from "./ContentLoader";
import { formatMoney } from "./Helpers/Formatters";
import DevNote from "./DevNote";

const CampaignRates = props => {
  const [rates, setRates] = useState(null);
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    api_request(
      "".concat("/admin/campaigns/", props.campaignId, "/rates")
    ).then(res => {
      const rateData = res.data.campaign_rates;
      setCurrency(res.data.currency);

      if (rateData.length > 0) {
        setRates(rateData);
      }
    });
  }, []);

  return (
    <>
      <Campaign campaignId={props.campaignId} />
      <CampaignTabs campaignId={props.campaignId} active="rates" />
      <DevNote note="Lists all rate changes applied to this campaign. If multiple rate changes happen on a single date, then the latest one applied is used." />
      {rates === null ? (
        "No rates available."
      ) : rates.length > 0 ? (
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Date</th>
              <th>Received</th>
              <th>Payout</th>
              <th>Received Overflow</th>
              <th>Payout Overflow</th>
            </tr>
          </thead>
          <tbody>
            {rates.map((rate, key) => {
              return (
                <tr key={key}>
                  <td>
                    {rate.date}{" "}
                    {key === rates.length - 1 ? "(original rate)" : null}
                  </td>
                  <td>{formatMoney(currency, rate.received)}</td>
                  <td>{formatMoney(currency, rate.payout)}</td>
                  <td>{formatMoney(currency, rate.received_overflow)}</td>
                  <td>{formatMoney(currency, rate.payout_overflow)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <ContentLoader />
      )}
    </>
  );
};

export default CampaignRates;
