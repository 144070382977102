import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import Breadcrumbs from "./Breadcrumbs";
import { api_request } from "./Helpers";
import {
  capitalizeFirstLetter,
  dateToString,
  formatMoney
} from "./Helpers/Formatters";
import { flag } from "country-emoji";
import ContentLoader from "./ContentLoader";

const Dashboard = () => {
  const [graphData, setGraphData] = useState(null);
  const [geosData, setGeosData] = useState(null);
  const [servicesData, setServicesData] = useState(null);
  const [affiliatesData, setAffiliatesData] = useState(null);

  useEffect(() => {
    const date_start = new Date();
    date_start.setDate(date_start.getDate() - 30);
    const date_end = new Date();

    // Graph.
    api_request(
      "".concat(
        "/admin/reporting/dashboard-graph/",
        dateToString(date_start),
        "/",
        dateToString(date_end)
      ),
      "GET"
    ).then(res => {
      const data = res.data.tally;
      setGraphData(data);
    });

    // Top Geos.
    api_request(
      "".concat(
        "/admin/reporting/dashboard-top-geos/",
        dateToString(date_start),
        "/",
        dateToString(date_end)
      ),
      "GET"
    ).then(res => {
      const data = res.data.tally;
      setGeosData(data);
    });

    // Top Services.
    api_request(
      "".concat(
        "/admin/reporting/dashboard-top-services/",
        dateToString(date_start),
        "/",
        dateToString(date_end)
      ),
      "GET"
    ).then(res => {
      const data = res.data.tally;
      setServicesData(data);
    });

    // Top Affiliates.
    api_request(
      "".concat(
        "/admin/reporting/dashboard-top-affiliates/",
        dateToString(date_start),
        "/",
        dateToString(date_end)
      ),
      "GET"
    ).then(res => {
      const data = res.data.tally;
      setAffiliatesData(data);
    });
  }, []);

  return (
    <>
      <Breadcrumbs title="Dashboard" />
      <div className="box">
        <ResponsiveContainer height={250} width="100%">
          <AreaChart
            data={graphData}
            margin={{ top: 0, right: 10, left: 10, bottom: 0 }}
          >
            <Legend verticalAlign="top" height={36} />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" interval={2} />
            <YAxis
              yAxisId="left"
              label={{
                value: "Clicks",
                angle: -90,
                position: "left",
                offset: 5
              }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              label={{
                value: "Conversions",
                angle: -90,
                position: "right",
                offset: 0
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="clicks"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
              yAxisId="left"
            />
            <Area
              type="monotone"
              dataKey="conversions"
              stroke="#82ca9d"
              fillOpacity={1}
              fill="url(#colorPv)"
              yAxisId="right"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="columns">
        <div className="column">
          <div className="box">
            <b>
              📈 Top <u>Geos</u> in the last 30 days
            </b>
            {geosData === null ? (
              "No data available."
            ) : geosData.length > 0 ? (
              <table className="table is-striped is-fullwidth">
                <thead>
                  <tr>
                    <th>Geo</th>
                    <th>Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {geosData.map((geo, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {"".concat(
                            flag(geo.country_code),
                            " ",
                            geo.country_name
                          )}
                        </td>
                        <td>{formatMoney("GBP", geo.received)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <ContentLoader />
            )}
          </div>
        </div>
        <div className="column">
          <div className="box">
            <b>
              📈 Top <u>Services</u> in the last 30 days
            </b>

            {servicesData === null ? (
              "No data available."
            ) : servicesData.length > 0 ? (
              <table className="table is-striped is-fullwidth">
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {servicesData.map((service, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {flag(service.country_code)}{" "}
                          {capitalizeFirstLetter(service.service)}
                        </td>
                        <td>{formatMoney("GBP", service.received)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <ContentLoader />
            )}
          </div>
        </div>
        <div className="column">
          <div className="box">
            <b>
              📈 Top <u>Affiliates</u> in the last 30 days
            </b>

            {affiliatesData === null ? (
              "No data available."
            ) : affiliatesData.length > 0 ? (
              <table className="table is-striped is-fullwidth">
                <thead>
                  <tr>
                    <th>Affiliate</th>
                    <th>Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {affiliatesData.map((affiliate, key) => {
                    return (
                      <tr key={key}>
                        <td>{affiliate.affiliate_name}</td>
                        <td>{formatMoney("GBP", affiliate.received)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <ContentLoader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
