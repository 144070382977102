import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faTable } from "@fortawesome/free-solid-svg-icons";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import AffiliateTabs from "./AffiliateTabs";
import Affiliate from "./Affiliate";

const AffiliateReports = props => {
  const data = [
    {
      name: "01/01/2020",
      clicks: 4000,
      conversions: 2400
    },
    {
      name: "02/01/2020",
      clicks: 2000,
      conversions: 1400
    },
    {
      name: "03/01/2020",
      clicks: 3400,
      conversions: 1200
    },
    {
      name: "04/01/2020",
      clicks: 2340,
      conversions: 1470
    },
    {
      name: "05/01/2020",
      clicks: 1040,
      conversions: 400
    },
    {
      name: "06/01/2020",
      clicks: 1000,
      conversions: 130
    },
    {
      name: "07/01/2020",
      clicks: 2000,
      conversions: 1400
    },
    {
      name: "08/01/2020",
      clicks: 1467,
      conversions: 894
    },
    {
      name: "09/01/2020",
      clicks: 693,
      conversions: 440
    },
    {
      name: "10/01/2020",
      clicks: 2500,
      conversions: 1403
    },
    {
      name: "11/01/2020",
      clicks: 656,
      conversions: 123
    },
    {
      name: "12/01/2020",
      clicks: 1233,
      conversions: 545
    },
    {
      name: "13/01/2020",
      clicks: 1232,
      conversions: 323
    },
    {
      name: "14/01/2020",
      clicks: 2453,
      conversions: 1342
    },
    {
      name: "15/01/2020",
      clicks: 1565,
      conversions: 213
    },
    {
      name: "16/01/2020",
      clicks: 1467,
      conversions: 894
    },
    {
      name: "17/01/2020",
      clicks: 2000,
      conversions: 1400
    },
    {
      name: "18/01/2020",
      clicks: 1467,
      conversions: 894
    },
    {
      name: "19/01/2020",
      clicks: 1545,
      conversions: 745
    },
    {
      name: "20/01/2020",
      clicks: 2343,
      conversions: 234
    },
    {
      name: "21/01/2020",
      clicks: 3454,
      conversions: 2344
    },
    {
      name: "22/01/2020",
      clicks: 1000,
      conversions: 930
    },
    {
      name: "23/01/2020",
      clicks: 1467,
      conversions: 894
    },
    {
      name: "24/01/2020",
      clicks: 2000,
      conversions: 1400
    },
    {
      name: "25/01/2020",
      clicks: 1467,
      conversions: 894
    },
    {
      name: "26/01/2020",
      clicks: 545,
      conversions: 343
    },
    {
      name: "27/01/2020",
      clicks: 2000,
      conversions: 1400
    },
    {
      name: "28/01/2020",
      clicks: 3056,
      conversions: 1744
    },
    {
      name: "29/01/2020",
      clicks: 3433,
      conversions: 1453
    },
    {
      name: "30/01/2020",
      clicks: 2345,
      conversions: 1654
    },
    {
      name: "31/01/2020",
      clicks: 1400,
      conversions: 1230
    }
  ];
  return (
    <>
      <Affiliate affiliateId={props.affiliateId} />
      <AffiliateTabs affiliateId={props.affiliateId} active="reports" />
      <div className="columns">
        <div className="column is-2 is-offset-10">
          <div className="tabs is-toggle is-toggle-rounded">
            <ul>
              <li>
                <a>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faTable} />
                  </span>
                  <span>Table</span>
                </a>
              </li>
              <li className="is-active">
                <a>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faChartLine} />
                  </span>
                  <span>Graph</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ResponsiveContainer height={250} width="100%">
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <Legend verticalAlign="top" height={36} />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" angle={-45} />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="clicks"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <Area
            type="monotone"
            dataKey="conversions"
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
          <Area
            type="monotone"
            dataKey="revenue"
            stroke="#1884d8"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default AffiliateReports;
