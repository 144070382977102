import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { api_request } from "./Helpers";
import Navbar from "./Navbar";
import UserContext from "./UserContext";
import Loading from "./Loading";
import Sidebar from "./Sidebar";
import Tags from "./Tags";
import Dashboard from "./Dashboard";
import { navigate, Router } from "@reach/router";
import { ToastContainer } from "react-toastify";
import "regenerator-runtime/runtime"; // For async/await.
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/bulma/tabulator_bulma.min.css";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css";
import "../css/admin.scss";
import "flatpickr/dist/themes/material_blue.css";

global.moment = require("moment");

import NotFound from "./NotFound";
import Geos from "./Geos";
import GeosAdd from "./GeosAdd";
import Root from "./Root";
import Affiliates from "./Affiliates";
import AffiliatesAdd from "./AffiliatesAdd";
import Campaigns from "./Campaigns";
import GeosEdit from "./GeosEdit";
import CampaignsAdd from "./CampaignsAdd";
import CampaignsEdit from "./CampaignsEdit";
import CampaignChangelog from "./CampaignChangelog";
import CampaignReports from "./CampaignReports";
import GeoReports from "./GeoReports";
import GeoChangelog from "./GeoChangelog";
import Batches from "./Batches";
import AffiliatesEdit from "./AffiliatesEdit";
import AffiliateChangelog from "./AffiliateChangelog";
import AffiliateReports from "./AffiliateReports";
import Batch from "./Batch";
import Reporting from "./Reporting";
import Users from "./Users";
import BatchItem from "./BatchItem";
import CampaignAdjustments from "./CampaignAdjustments";
import UserChangelog from "./UserChangelog";
import CampaignRates from "./CampaignRates";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Channels from "./Channels";
import ChannelsAdd from "./ChannelsAdd";
import ChannelsEdit from "./ChannelsEdit";

const App = () => {
  const [user, setUser] = useState(null);

  const url_string = window.location.href;
  const url = new URL(url_string);
  const auth_code = url.searchParams.get("code");
  const previous_path = url.searchParams.get("state");

  const hasNoError = (data) => data && ! Object.prototype.hasOwnProperty.call(data, "error")

  useEffect(() => {
    const timer = setTimeout(() => {
      if (auth_code) {
        api_request("/admin/login", "GET", { code: auth_code }).then(res => {
          const data = res && res.data ? res.data : null;

          if (hasNoError(data)) {
            setUser(data);
            // Navigate to where
            // the user was beforehand.
            navigate(previous_path);
          }
          // let them hang
        });
        return;
      }

      api_request("/admin/users/me", "GET").then(res => {
        const data = res && res.data ? res.data : null;

        if (hasNoError(data)) {
          setUser(data);
          return;
        }

        // User not logged in, redirect to STAMP.
        // The current path is passed via the state parameter so that we can
        // redirect the user to their previous location.
        window.location.replace(
          "".concat(
            process.env.MS_AUTH_URL,
            "&state=",
            window.location.pathname
          )
        );
      });
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // When the status of the user is yet to be checked.
  if (user === null) {
    return <Loading />;
  }

  // TODO: Show error page is any of the initial calls fail.

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <UserContext.Provider value={{ user: user }}>
        <ToastContainer />
        <Navbar />
        <div className="columns">
          <div className="column is-2 aside">
            <Sidebar />
          </div>
          <div className="column is-10 aside">
            <div className="box">
              {/* primary={false} is needed to stop scrolling when changing pages */}
              <Router primary={false}>
                <NotFound default />
                <Root path="/" />
                <Dashboard path="dashboard" />

                <Geos path="geos" />
                <GeosAdd path="geos/add" />
                <GeosEdit path="geos/edit/:geoId" />
                <GeoReports path="geos/:geoId/reports" />
                {/*<GeoReports path="geos/:geoId" />*/}
                <GeoChangelog path="geos/:geoId/changelog" />
                <GeoChangelog path="geos/:geoId" />

                <Affiliates path="affiliates" />
                <AffiliatesAdd path="affiliates/add" />
                <AffiliatesEdit path="affiliates/edit/:affiliateId" />
                {/*<AffiliateReports path="affiliates/:affiliateId" />*/}
                <AffiliateReports path="affiliates/:affiliateId/reports" />
                <AffiliateChangelog path="affiliates/:affiliateId/changelog" />
                <AffiliateChangelog path="affiliates/:affiliateId" />

                <Campaigns path="campaigns" />
                <CampaignsAdd path="campaigns/add" />
                <CampaignsEdit path="campaigns/edit/:campaignId" />
                {/*<CampaignReports path="campaigns/:campaignId" />*/}
                <CampaignReports path="campaigns/:campaignId/reports" />
                <CampaignChangelog path="campaigns/:campaignId/changelog" />
                <CampaignChangelog path="campaigns/:campaignId" />
                <CampaignAdjustments path="campaigns/:campaignId/adjustments" />
                <CampaignRates path="campaigns/:campaignId/rates" />

                <Batches path="batches" />
                <Batch path="batches/:batchId" isOverflow={false} />
                <Batch path="batches/overflow/:batchId" isOverflow={true} />
                <BatchItem
                  path="batches/items/:batchItemId"
                  isOverflow={false}
                />

                <Reporting path="reporting/geos" section="geos" />
                <Reporting path="reporting/affiliates" section="affiliates" />
                <Reporting path="reporting/campaigns" section="campaigns" />

                <Users path="users" />
                <UserChangelog path="users/:userId/changelog" />

                <Channels path="channels" />
                <ChannelsAdd path="channels/add" />
                <ChannelsEdit path="channels/edit/:channelId" />

                <Tags path="tags" />
              </Router>
            </div>
          </div>
        </div>
      </UserContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

Sentry.init({
  dsn: "https://418e446008154b61be6766feba67889b@sentry.stechga.co.uk/46",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1
});

render(<App />, document.getElementById("root"));
