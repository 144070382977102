import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import CampaignsAddEdit from "./CampaignsAddEdit";

const CampaignsEdit = props => {
  return (
    <>
      <Breadcrumbs
        crumbs={{
          Campaigns: "/campaigns"
        }}
        title="Edit Campaign"
      />
      <CampaignsAddEdit campaignId={props.campaignId} />
    </>
  );
};

export default CampaignsEdit;
