import React from "react";
import { Link } from "@reach/router";
import Search from "./Search";

const Sidebar = () => {
  const isActive = ({ isCurrent, isPartiallyCurrent }) => {
    return isCurrent || isPartiallyCurrent ? { className: "is-active" } : null;
  };

  return (
    <aside className="menu">
      {/*<ul className="menu-list">*/}
      {/*  <li>*/}
      {/*    <Search />*/}
      {/*    <br />*/}
      {/*  </li>*/}
      {/*</ul>*/}
      <ul className="menu-list">
        <li>
          <Link getProps={isActive} to="dashboard">
            Dashboard
          </Link>
        </li>
      </ul>
      <p className="menu-label">Management</p>
      <ul className="menu-list">
        <li>
          <Link getProps={isActive} to="geos">
            Geos
          </Link>
          <Link getProps={isActive} to="affiliates">
            Affiliates
          </Link>
          <Link getProps={isActive} to="campaigns">
            Campaigns
          </Link>
          <Link getProps={isActive} to="batches">
            Batches
          </Link>
          <Link getProps={isActive} to="channels">
            Channels
          </Link>
          <Link getProps={isActive} to="users">
            Users
          </Link>
        </li>
      </ul>
      <p className="menu-label">Reporting</p>
      <ul className="menu-list">
        <li>
          <Link getProps={isActive} to="reporting/geos">
            Geos
          </Link>
        </li>
        <li>
          <Link getProps={isActive} to="reporting/affiliates">
            Affiliates
          </Link>
        </li>
        <li>
          <Link getProps={isActive} to="reporting/campaigns">
            Campaigns
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
