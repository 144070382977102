import React from "react";
import { ReactTabulator } from "react-tabulator";
import Breadcrumbs from "./Breadcrumbs";

const Tags = () => {
  const columns = [
    {
      title: "Tag",
      field: "tag",
      headerFilter: "input",
      formatter: "link",
      formatterParams: {
        labelField: "tag",
        urlPrefix: "/tag/",
        target: "_blank"
      }
    },
    { title: "Type", field: "type" },
    { title: "Affiliate", field: "affiliate", headerFilter: "input" },
    { title: "Campaign", field: "campaign" },
    { title: "Received", field: "received" },
    { title: "Payout", field: "payout" }
  ];

  var data = [
    {
      tag: "dvm_uk_ass_mae_CPA9_8_15",
      affiliate: "Affiliator",
      type: "Prime",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Prime",
      affiliate: "Traffic Catapult",
      campaign: "FR Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Music",
      affiliate: "Legit Leads",
      campaign: "UK Music",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_cpa102_10_17-21",
      type: "Prime",
      affiliate: "Dodgy Man in unclean dwellings",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA9_8_15",
      type: "Prime",
      affiliate: "Affiliator",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Prime",
      affiliate: "Traffic Catapult",
      campaign: "FR Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Music",
      affiliate: "Legit Leads",
      campaign: "UK Music",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_cpa102_10_17-21",
      type: "Prime",
      affiliate: "Dodgy Man in unclean dwellings",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA9_8_15",
      type: "Prime",
      affiliate: "Affiliator",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Prime",
      affiliate: "Traffic Catapult",
      campaign: "FR Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Music",
      affiliate: "Legit Leads",
      campaign: "UK Music",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_cpa102_10_17-21",
      type: "Prime",
      affiliate: "Dodgy Man in unclean dwellings",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA9_8_15",
      type: "Prime",
      affiliate: "Affiliator",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Prime",
      affiliate: "Traffic Catapult",
      campaign: "FR Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Music",
      affiliate: "Legit Leads",
      campaign: "UK Music",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_cpa102_10_17-21",
      type: "Prime",
      affiliate: "Dodgy Man in unclean dwellings",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA9_8_15",
      type: "Prime",
      affiliate: "Affiliator",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Prime",
      affiliate: "Traffic Catapult",
      campaign: "FR Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Music",
      affiliate: "Legit Leads",
      campaign: "UK Music",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_cpa102_10_17-21",
      type: "Prime",
      affiliate: "Dodgy Man in unclean dwellings",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA9_8_15",
      type: "Prime",
      affiliate: "Affiliator",
      campaign: "UK Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Prime",
      affiliate: "Traffic Catapult",
      campaign: "FR Prime",
      received: 18,
      payout: 10
    },
    {
      tag: "dvm_uk_ass_mae_CPA2_8_15",
      type: "Music",
      affiliate: "Legit Leads",
      campaign: "UK Music",
      received: 18,
      payout: 10
    }
  ];

  const options = {
    layout: "fitDataStretch"
  };

  return (
    <>
      <Breadcrumbs title="Tags" />
      <ReactTabulator
        data={data}
        columns={columns}
        tooltips={true}
        options={options}
      />
    </>
  );
};

export default Tags;
