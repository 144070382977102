import React, { useEffect, useState } from "react";
import { api_request } from "./Helpers";
import Breadcrumbs from "./Breadcrumbs";
import ContentLoader from "./ContentLoader";
import { flag } from "country-emoji";
import { ServiceFormatterNonTable } from "./Helpers/TableFormatters";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowRight,
  faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import CopyLink from "./Helpers/CopyLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@reach/router";
import { formatMoney } from "./Helpers/Formatters";

const Campaign = props => {
  const [campaign, setCampaign] = useState(null);
  const [hideDetails, setHideDetails] = useState(true);

  useEffect(() => {
    api_request("/admin/campaigns/".concat(props.campaignId), "GET").then(
      res => {
        setCampaign(res.data.campaign);
      }
    );
  }, []);

  function handleShowDetails() {
    setHideDetails(!hideDetails);
  }

  return (
    <>
      <Breadcrumbs
        crumbs={{
          Campaigns: "/campaigns"
        }}
        title="Campaign"
      />
      {campaign === null ? (
        <ContentLoader />
      ) : (
        <>
          <div className="buttons has-addons is-right">
            <Link to={"/campaigns/edit/".concat(campaign.id)}>
              <button className="button is-primary">
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faPencilAlt} />
                </span>
                <span>Edit Campaign</span>
              </button>
            </Link>
          </div>
          <div className="box">
            <>
              <nav className="level">
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Geo</p>
                    <p className="title">{flag(campaign.country_code)}</p>
                  </div>
                </div>
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Service</p>
                    <p className="title">
                      <ServiceFormatterNonTable
                        service={
                          campaign.channel
                            ? "".concat(
                                campaign.service,
                                " (",
                                campaign.channel,
                                ")"
                              )
                            : campaign.service
                        }
                      />
                    </p>
                  </div>
                </div>
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Affiliate</p>
                    <p className="title">{campaign.affiliate_name}</p>
                  </div>
                </div>
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Received</p>
                    <p className="title">
                      {formatMoney(campaign.currency, campaign.received)}
                    </p>
                  </div>
                </div>
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Payout</p>
                    <p className="title">
                      {formatMoney(campaign.currency, campaign.payout)}
                    </p>
                  </div>
                </div>
              </nav>
              <article className="message is-info">
                <div
                  className="message-header no-user-select"
                  style={{ cursor: "pointer" }}
                  onClick={handleShowDetails}
                >
                  <p>Full Campaign Details</p>
                  <span className="icon is-small">
                    <FontAwesomeIcon
                      icon={hideDetails ? faArrowCircleDown : faArrowCircleUp}
                    />
                  </span>
                </div>
                {hideDetails ? null : (
                  <div className="message-body">
                    <div className="columns">
                      <div className="column has-text-centered">
                        <p>
                          <b>Campaign URL </b>
                        </p>
                        <p>
                          <span className="tag is-info">
                            <CopyLink
                              link={"".concat(
                                process.env.API_URL,
                                "/c/",
                                campaign.external_id
                              )}
                            />
                          </span>
                          <span className="icon is-medium">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </span>
                          <span className="tag is-info">
                            {campaign.redirect_url}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <p>
                          <b>Geo:</b>{" "}
                          {"".concat(
                            flag(campaign.country_code),
                            " ",
                            campaign.country_name
                          )}
                        </p>
                        <p>
                          <b>Service:</b>{" "}
                          <ServiceFormatterNonTable
                            service={
                              campaign.channel
                                ? "".concat(
                                    campaign.service,
                                    " (",
                                    campaign.channel,
                                    ")"
                                  )
                                : campaign.service
                            }
                          />
                        </p>
                        <p>
                          <b>Affiliate:</b>{" "}
                          <Link
                            to={"/affiliates/".concat(campaign.affiliate_id)}
                          >
                            {campaign.affiliate_name}
                          </Link>
                        </p>
                        <p>
                          <b>Adzuki ID:</b> {campaign.external_id}
                        </p>
                        <p>
                          <b>Cake ID:</b> {campaign.affiliate_cake_id}
                        </p>
                        <p>
                          <b>Created By:</b> {campaign.stamp_user}
                        </p>
                      </div>
                      <div className="column">
                        <p>
                          <b>Currency:</b> {campaign.currency}
                        </p>
                        <p>
                          <b>Received:</b>{" "}
                          {formatMoney(campaign.currency, campaign.received)}
                        </p>
                        <p>
                          <b>Payout:</b>{" "}
                          {formatMoney(campaign.currency, campaign.payout)}
                        </p>
                      </div>
                      <div className="column">
                        <p>
                          <b>Traffic Type:</b>{" "}
                          {campaign.traffic_type
                            ? campaign.traffic_type
                            : "N/A"}
                        </p>
                        <p>
                          <b>Tag:</b> {campaign.tag}
                        </p>
                        <p>
                          <b>Postback URL:</b>
                          <span className="tag is-info">
                            {campaign.postback_url
                              ? campaign.postback_url
                              : "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column is-narrow">
                        <p>
                          <b>Notes:</b>
                        </p>
                        <p style={{ whiteSpace: "pre-line" }}>
                          {campaign.notes ? campaign.notes : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </article>
            </>
          </div>
        </>
      )}
    </>
  );
};

export default Campaign;
