import React from "react";
import { Link } from "@reach/router";

const Breadcrumbs = props => {
  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        {props.title !== "Dashboard" ? (
          <ul>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            {props.crumbs
              ? Object.entries(props.crumbs).map(([crumb, url]) => {
                  return (
                    <li key={crumb}>
                      <Link to={url}>{crumb}</Link>
                    </li>
                  );
                })
              : null}
            <li className="is-active">
              <a href="#" aria-current="page">
                {props.title}
              </a>
            </li>
          </ul>
        ) : null}
        <h1 className="title">
          {props.title} <hr />
        </h1>
      </nav>
    </>
  );
};

export default Breadcrumbs;
