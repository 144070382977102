import React, { useEffect, useState } from "react";
import {
  faEnvelope,
  faLink,
  faPlus,
  faTag,
  faUser,
    faBirthdayCake
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api_request } from "./Helpers";
import Select from "react-select";
import { flag } from "country-emoji";
import FormValidationMessage from "./FormValidationMessage";
import { navigate } from "@reach/router";
import ContentLoader from "./ContentLoader";
import { iconFromCurrencyCode } from "./Helpers/Formatters";
import {
  errMissingMonetaryVal,
  errMissingTag,
  errMissingTagFormat,
  errRequired
} from "./Helpers/FormValidation";

const GeosAddEditForm = props => {
  // Service list for select field.
  const services = [
    { value: "prime", label: "Prime" },
    { value: "music", label: "Music" },
    { value: "channels", label: "Channels" }
  ];

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // Select values that need to be loaded via AJAX.
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [channels, setChannels] = useState([]);

  // For use during edit.
  // Has the geo ajax call loaded?
  const [geoLoaded, setGeoLoaded] = useState(false);
  const [currencyIcon, updateCurrencyIcon] = useState(null);

  // Stupid react-select values. What a terrible way to take values.
  const [currencySelectValue, setCurrencySelectValue] = useState(null);
  const [geoSelectValue, setGeoSelectValue] = useState(null);
  const [serviceSelectValue, setServiceSelectValue] = useState(null);
  const [channelSelectValue, setChannelSelectValue] = useState(null);

  // Form values.
  const [geo, updateGeo] = useState("");
  const [service, updateService] = useState("");
  const [channelId, updateChannelId] = useState(0);
  const [currency, updateCurrency] = useState("");
  const [baseUrl, updateBaseUrl] = useState("");
  const [tagFormat, updateTagFormat] = useState("");
  const [received, updateReceived] = useState(0);
  const [payout, updatePayout] = useState(0);
  const [payoutOverflow, updatePayoutOverflow] = useState(0);
  const [receivedOverflow, updateReceivedOverflow] = useState(0);
  const [contactName, updateContactName] = useState("");
  const [contactEmail, updateContactEmail] = useState("");
  const [cakeOfferID, updateCakeOfferID] = useState(null);
  const [cakeOfferIDOverflow, updateCakeOfferIDOverflow] = useState(null);

  // Validation messages.
  const [geoValidationMsg, setGeoValidationMsg] = useState(null);
  const [serviceValidationMsg, setServiceValidationMsg] = useState(null);
  const [channelValidationMsg, setChannelValidationMsg] = useState(null);
  const [currencyValidationMsg, setCurrencyValidationMsg] = useState(null);
  const [baseUrlValidationMsg, setBaseUrlValidationMsg] = useState(null);
  const [tagFormatValidationMsg, setTagFormatValidationMsg] = useState(null);
  const [receivedValidatonMsg, setReceivedValidationMsg] = useState(null);
  const [payoutValidationMsg, setPayoutValidationMsg] = useState(null);

  // For any errors returned by the backend.
  const [backendError, setBackendError] = useState(null);

  useEffect(() => {
    // Get currencies.
    api_request("/admin/currencies", "GET").then(res => {
      let currencies = res.data.currencies;
      let currenciesSel = [];
      // Build list for currencies selection.
      for (let key in currencies) {
        let currency = currencies[key];

        currenciesSel.push({
          value: currency.iso_code,
          label: currency.name
        });
      }
      setCurrencies(currenciesSel);
    });

    // Edit?
    if (props.geoId) {
      api_request("/admin/geos/".concat(props.geoId), "GET").then(res => {
        const geo = res.data.geo;

        updateGeo(geo.country_code);
        setGeoSelectValue({
          value: geo.country_code,
          label: "".concat(flag(geo.country_code), " ", geo.country_name)
        });

        updateService(geo.service);
        setServiceSelectValue({
          value: geo.service,
          label: geo.service.charAt(0).toUpperCase() + geo.service.slice(1)
        });

        updateBaseUrl(geo.base_url);
        updateReceived(geo.default_received);
        updatePayout(geo.default_payout);
        updateReceivedOverflow(geo.default_received_overflow);
        updatePayoutOverflow(geo.default_payout_overflow);

        if (geo.contact_name) {
          updateContactName(geo.contact_name);
        }

        if (geo.contact_email) {
          updateContactEmail(geo.contact_email);
        }

        if (geo.cake_offer_id){
          updateCakeOfferID(geo.cake_offer_id);
        }

        if (geo.cake_offer_id_overflow){
          updateCakeOfferIDOverflow(geo.cake_offer_id_overflow);
        }

        updateTagFormat(geo.tag_format);

        if (geo.prime_channel_id > 0) {
          api_request(
            "/admin/channels/".concat(geo.prime_channel_id),
            "GET"
          ).then(res => {
            const channel = res.data.channel;

            updateChannelId(channel.id);
            setChannelSelectValue({
              value: channel.id,
              label: channel.channel
            });
          });
        }

        // Need to grab the full currency details in order to set the value for react-select.
        api_request(
          "/admin/currencies/iso/".concat(geo.default_currency),
          "GET"
        ).then(res => {
          const currency = res.data.currency;
          // For react select.
          setCurrencySelectValue({
            value: currency.iso_code,
            label: currency.name
          });
          // For the actual form value to be passed to the backend.
          updateCurrency(currency.iso_code);

          // Icon for the input fields.
          updateCurrencyIcon(iconFromCurrencyCode(geo.default_currency));

          // Allow for the form to show.
          setGeoLoaded(true);
        });
      });
      return;
    }

    // New geo. Don't allow geo to be updated.
    // Get countries.
    api_request("/admin/geos/countries", "GET").then(res => {
      let countries = res.data.countries;
      let countriesSel = [];
      // Build list for geo selection based on the flag and name of countries.
      for (let key in countries) {
        let country = countries[key];

        countriesSel.push({
          value: country.iso_code,
          label: "".concat(flag(country.iso_code), " ", country.name)
        });
      }
      setCountries(countriesSel);
    });

    // Get channels list (toconsider: this could only be loaded when channels is selected as a service).
    api_request("/admin/channels", "GET").then(res => {
      let channels = res.data.channels;
      let channelsSel = [];
      // Build list for channels selection.
      for (let key in channels) {
        let channel = channels[key];

        channelsSel.push({
          value: channel.id,
          label: channel.channel
        });
      }
      setChannels(channelsSel);
    });
  }, []);

  function submit() {
    // Form submission.
    setIsFormSubmitted(true);

    let failedValidation = false;

    setGeoValidationMsg(null);
    setServiceValidationMsg(null);
    setChannelValidationMsg(null);
    setCurrencyValidationMsg(null);
    setBaseUrlValidationMsg(null);
    setTagFormatValidationMsg(null);
    setReceivedValidationMsg(null);
    setPayoutValidationMsg(null);

    setBackendError(null);

    if (geo === "") {
      failedValidation = true;
      setGeoValidationMsg(errRequired);
    }

    if (service === "") {
      failedValidation = true;
      setServiceValidationMsg(errRequired);
    }

    if (currency === "") {
      failedValidation = true;
      setCurrencyValidationMsg(errRequired);
    }

    if (baseUrl === "") {
      failedValidation = true;
      setBaseUrlValidationMsg(errRequired);
    } else {
      if (!baseUrl.includes("[tag]")) {
        failedValidation = true;
        setBaseUrlValidationMsg(errMissingTag);
      }
    }

    if (service === "channels" && !channelId) {
      setChannelValidationMsg(errRequired);
    }

    if (tagFormat === "") {
      failedValidation = true;
      setTagFormatValidationMsg(errRequired);
    } else {
      if (!tagFormat.includes("[tag_id]")) {
        failedValidation = true;
        setTagFormatValidationMsg(errMissingTagFormat);
      }
    }

    if (received <= 0) {
      failedValidation = true;
      setReceivedValidationMsg(errMissingMonetaryVal);
    }

    if (payout <= 0) {
      failedValidation = true;
      setPayoutValidationMsg(errMissingMonetaryVal);
    }

    if (failedValidation) {
      setIsFormSubmitted(false);
      return;
    }

    let params = {
      geo: geo,
      service: service,
      currency: currency,
      base_url: baseUrl,
      tag_format: tagFormat,
      received: received,
      payout: payout,
      received_overflow: receivedOverflow,
      payout_overflow: payoutOverflow,
      contact_name: contactName,
      contact_email: contactEmail,
      channel_id: channelId,
    };

    if (props.geoId) {
      params.geo_id = props.geoId;
    }

    if (cakeOfferID) {
      params.cake_offer_id = cakeOfferID;
    }

    if (cakeOfferIDOverflow){
      params.cake_offer_id_overflow = cakeOfferIDOverflow;
    }

    api_request("/admin/geos", "POST", params).then(res => {
      let data = res.data;
      if ("error" in data) {
        setIsFormSubmitted(false);
        setBackendError(data["error"]);
        return;
      }

      if (props.geoId) {
        navigate("/geos", { state: { geoEdited: true } });
      } else {
        navigate("/geos", { state: { newGeoID: data["geo_id"] } });
      }
    });
  }

  function handleGeoChange(selectedValue) {
    setGeoSelectValue(selectedValue);
    updateGeo(selectedValue.value);
  }

  function handleServiceChange(selectedValue) {
    setServiceSelectValue(selectedValue);
    updateService(selectedValue.value);
  }

  function handleCurrencyChange(selectedValue) {
    setCurrencySelectValue(selectedValue);
    updateCurrency(selectedValue.value);
    updateCurrencyIcon(iconFromCurrencyCode(selectedValue.value));
  }

  function handleChannelChange(selectedValue) {
    setChannelSelectValue(selectedValue);
    updateChannelId(selectedValue.value);
  }

  // Editing a geo and waiting for it to load...
  if (props.geoId && geoLoaded === false) {
    return <ContentLoader />;
  }

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Geo</label>
          </div>
          <div className="field-body">
            <div className="field">
              <>
                <Select
                  options={countries}
                  maxMenuHeight={200}
                  onChange={handleGeoChange}
                  value={geoSelectValue}
                  isDisabled={!!props.geoId}
                />
                <FormValidationMessage message={geoValidationMsg} />
              </>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Service</label>
          </div>
          <div className="field-body">
            <div className="field">
              <>
                <Select
                  options={services}
                  onChange={handleServiceChange}
                  value={serviceSelectValue}
                  isDisabled={!!props.geoId}
                />
                <FormValidationMessage message={serviceValidationMsg} />
              </>
            </div>
          </div>
        </div>
        {service === "channels" ? (
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Channel</label>
            </div>
            <div className="field-body">
              <div className="field">
                <>
                  <Select
                    options={channels}
                    onChange={handleChannelChange}
                    value={channelSelectValue}
                    isDisabled={!!props.geoId}
                  />
                  <FormValidationMessage message={channelValidationMsg} />
                </>
              </div>
            </div>
          </div>
        ) : null}

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Currency</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Select
                options={currencies}
                isLoading={currencies.length === 0}
                onChange={handleCurrencyChange}
                value={currencySelectValue}
              />
              <FormValidationMessage message={currencyValidationMsg} />
              <p className="help">
                {props.geoId
                  ? "Changing the default currency will only affect new campaigns."
                  : "The default currency for this geo. This can be overridden at the campaign level."}
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Amazon Base URL</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  placeholder="https://amazon.com?ref=[tag]"
                  value={baseUrl}
                  onChange={e => updateBaseUrl(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faLink} />
                </span>
              </p>
              <FormValidationMessage message={baseUrlValidationMsg} />
              <p className="help">
                The destination URL for this Amazon service. [tag] is replaced
                by the actual campaign tag.
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Tag Format</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  placeholder="dvm_ass_mdi_ww_jn_s_cw[tag_id]-20"
                  value={tagFormat}
                  onChange={e => updateTagFormat(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faTag} />
                </span>
              </p>
              <FormValidationMessage message={tagFormatValidationMsg} />
              <p className="help">
                The Tag Format for campaigns. [tag_id] represents the
                replacement value, which will be unique to each campaign.
                <br />
                <b>
                  Note: Updating this value will not change existing campaigns.
                  Changes will only affect new campaigns.
                </b>
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Default Received</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="number"
                  placeholder="10"
                  value={received}
                  onChange={e => updateReceived(e.target.value)}
                />
                <span className="icon is-small is-left">{currencyIcon}</span>
              </p>
              <FormValidationMessage message={receivedValidatonMsg} />
              <p className="help">
                How much we are paid per conversion. This can be overriden at
                the campaign level.
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Default Payout</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="number"
                  placeholder="5"
                  value={payout}
                  onChange={e => updatePayout(e.target.value)}
                />
                <span className="icon is-small is-left">{currencyIcon}</span>
              </p>
              <FormValidationMessage message={payoutValidationMsg} />
              <p className="help">
                How much we pay affiliates per conversion. This can be overridden
                at the campaign level.
              </p>
            </div>
          </div>
        </div>

        {service === "channels" ? (
          <>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Default Received Overflow</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="number"
                      placeholder="10"
                      value={receivedOverflow}
                      onChange={e => updateReceivedOverflow(e.target.value)}
                    />
                    <span className="icon is-small is-left">
                      {currencyIcon}
                    </span>
                  </p>
                  <FormValidationMessage message={receivedValidatonMsg} />
                  <p className="help">
                    How much we are paid per overflow conversion. This can be
                    overridden at the campaign level.
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Default Payout Overflow</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="number"
                      placeholder="5"
                      value={payoutOverflow}
                      onChange={e => updatePayoutOverflow(e.target.value)}
                    />
                    <span className="icon is-small is-left">
                      {currencyIcon}
                    </span>
                  </p>
                  <FormValidationMessage message={payoutValidationMsg} />
                  <p className="help">
                    How much we pay affiliates per overflow conversion. This can
                    be overriden at the campaign level.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : null}

         <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">CAKE Offer ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="number"
                  placeholder=""
                  value={cakeOfferID}
                  onChange={e => updateCakeOfferID(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faBirthdayCake} />
                </span>
              </p>
              <p className="help">
                The CAKE Offer ID for this Geo. <b>This is required for batches.</b>
              </p>
            </div>
          </div>
        </div>

        {service === "prime" ? (
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">CAKE Offer ID (Overflow)</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="number"
                  placeholder=""
                  value={cakeOfferIDOverflow}
                  onChange={e => updateCakeOfferIDOverflow(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faBirthdayCake} />
                </span>
              </p>
              <p className="help">
                The CAKE Offer ID for this Geo. <b>This is required for overflow batches.</b>
              </p>
            </div>
          </div>
        </div>
        ) : null}

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Contact</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  placeholder="Name"
                  value={contactName}
                  onChange={e => updateContactName(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faUser} />
                </span>
              </p>
              <p className="help">The Amazon employee contact name.</p>
            </div>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  type="email"
                  placeholder="Email"
                  value={contactEmail}
                  onChange={e => updateContactEmail(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </p>
              <p className="help">The Amazon employee contact email.</p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label" />
          <div className="field-body">
            <div className="field">
              <FormValidationMessage message={backendError} />
              <div className="control">
                <button
                  className={
                    "button is-primary " + (isFormSubmitted ? "is-loading" : "")
                  }
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                  <span> {props.geoId ? "Edit Geo" : "New Geo"}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default GeosAddEditForm;
