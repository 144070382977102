import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import Breadcrumbs from "./Breadcrumbs";
import { reactFormatter, ReactTabulator } from "react-tabulator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { api_request } from "./Helpers";
import ContentLoader from "./ContentLoader";
import { ActionsFormatter } from "./Helpers/TableFormatters";

const Affiliates = props => {
  const columns = [
    { title: "Affiliate", field: "affiliate", headerFilter: "input" },
    { title: "Cake ID", field: "cake_id", headerFilter: "input" },
    { title: "Payment Currency", field: "payment_currency" },
    { title: "Payment Setting", field: "payment_setting_name" },
    {
      title: "Postback?",
      field: "postback",
      hozAlign: "center",
      width: 130,
      formatter: "tickCross"
    },
    {
      title: "",
      field: "actions",
      formatter: reactFormatter(<ActionsFormatter section="affiliates" />),
      headerSort: false
    }
  ];

  const options = {
    layout: "fitColumns",
    pagination: "local",
    paginationSize: 20
  };

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    api_request("/admin/affiliates", "GET").then(res => {
      const affiliates = res.data.affiliates;
      let affiliatesData = [];

      if (affiliates.length === 0) {
        setTableData(null);
        return;
      }

      for (let key in affiliates) {
        const affiliate = affiliates[key];

        affiliatesData.push({
          id: affiliate.id,
          cake_id: affiliate.cake_id,
          affiliate: affiliate.name,
          payment_currency: affiliate.payment_currency,
          payment_setting_name: affiliate.payment_setting_name,
          postback: !!affiliate.postback_url
        });
      }
      setTableData(affiliatesData);
    });
  }, []);

  return (
    <>
      <Breadcrumbs title="Affiliates" />
      {props.location.state && props.location.state.newAffiliateId ? (
        <div className="notification is-info">
          New affiliate added! Click{" "}
          <Link to={props.location.state.newAffiliateId.toString()}>here</Link>{" "}
          to view it.
        </div>
      ) : null}
      {props.location.state && props.location.state.editAffiliateId ? (
        <div className="notification is-info">
          Affiliate has been successfully edited! Click{" "}
          <Link to={props.location.state.editAffiliateId.toString()}>here</Link>{" "}
          to view it.
        </div>
      ) : null}
      <p>
        <b>Note:</b> The information below was initially retrieved from CAKE.
      </p>
      <div className="buttons has-addons is-right">
        <Link to="add">
          <button className="button is-primary">
            <span className="icon is-small">
              <FontAwesomeIcon icon={faPlus} />
            </span>
            <span> Add Affiliate</span>
          </button>
        </Link>
      </div>
      {tableData === null ? (
        "No Affiliates available."
      ) : tableData.length > 0 ? (
        <ReactTabulator
          data={tableData}
          columns={columns}
          tooltips={true}
          options={options}
        />
      ) : (
        <ContentLoader />
      )}
    </>
  );
};

export default Affiliates;
