// Used to display validation errors for individual fields in forms.
import React from "react";

const FormValidationMessage = props => {
  return props.message ? (
    <p className="is-size-7 has-text-danger">{props.message}</p>
  ) : (
    ""
  );
};

export default FormValidationMessage;
