import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faEuroSign,
  faPoundSign
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

function iconFromCurrencyCode(currency) {
  // Returns the FA icon for a supplied currency code.
  let icon = null;
  switch (currency) {
    case "GBP":
      icon = <FontAwesomeIcon icon={faPoundSign} />;
      break;
    case "USD":
      icon = <FontAwesomeIcon icon={faDollarSign} />;
      break;
    case "EUR":
      icon = <FontAwesomeIcon icon={faEuroSign} />;
      break;
  }

  return icon;
}

function formatMoney(currency, value) {
  return new Intl.NumberFormat("gb-GB", {
    style: "currency",
    currency: currency
  }).format(value);
}

function dateToString(date) {
  return date.toLocaleDateString("en-GB").replace(/\//g, "-");
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
  iconFromCurrencyCode,
  formatMoney,
  dateToString,
  capitalizeFirstLetter
};
