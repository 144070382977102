import React, { useEffect, useState } from "react";
import { api_request } from "./Helpers";
import Breadcrumbs from "./Breadcrumbs";
import { flag } from "country-emoji";
import ContentLoader from "./ContentLoader";
import {
  ActionsFormatter,
  BatchCampaignLinkFormatter,
  ServiceFormatterNonTable
} from "./Helpers/TableFormatters";
import { reactFormatter, ReactTabulator } from "react-tabulator";
import {
  dateToString,
  formatMoney,
  iconFromCurrencyCode
} from "./Helpers/Formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSkull } from "@fortawesome/free-solid-svg-icons";
import fileDownload from "js-file-download";
import { navigate } from "@reach/router";

const Batch = props => {
  const [batch, setBatch] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [totalBatchItems, setTotalBatchItems] = useState(null);
  const [isDownloading, setIsDownloading] = useState(null);
  const [isDownloadingPreBatchCSV, setIsDownloadingPreBatchCSV] = useState(
    null
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submittingDelete, setSubmittingDelete] = useState(false);

  let columns = [
    {
      title: "Campaign ID",
      field: "campaign_id",
      formatter: reactFormatter(<BatchCampaignLinkFormatter />)
    },
    { title: "Affiliate", field: "affiliate" },
    { title: "Tag", field: "tag" },
    {
      title: "Date",
      field: "date",
      sorter: "date",
      sorterParams: { format: "DD/MM/YYYY" }
    },
    { title: "Conversions", field: "conversions", bottomCalc: "sum" },
    { title: "Received", field: "received" },
    { title: "Payout", field: "payout" }
  ];

  // Only non overflow batches have postbacks.
  if (!props.isOverflow) {
    columns = columns.concat({
      title: "",
      field: "actions",
      formatter: reactFormatter(
        <ActionsFormatter section="batches/items" viewOnly={true} />
      ),
      headerSort: false
    });
  }

  useEffect(() => {
    let batchesUrl = "/admin/batches/";
    let batchItemsUrl = "/admin/batches/items/";
    if (props.isOverflow) {
      batchesUrl = "/admin/batches/overflow/";
      batchItemsUrl = "/admin/batches/items/overflow/";
    }

    api_request(batchesUrl.concat(props.batchId), "GET").then(res => {
      setBatch(res.data.batch);

      api_request(batchItemsUrl.concat(props.batchId), "GET").then(res => {
        const batchItems = res.data.batch_items;
        let batchItemsData = [];

        for (let key in batchItems) {
          const item = batchItems[key];

          batchItemsData.push({
            id: item.id,
            campaign_id: item.campaign_id,
            affiliate: item.affiliate_name,
            tag: item.tag,
            date: item.date,
            conversions: item.conversions,
            received: formatMoney(item.currency, item.received_conversion),
            payout: formatMoney(item.currency, item.payout_conversion)
          });
        }

        setTotalBatchItems(res.data.total_batch_items);
        setTableData(batchItemsData);
      });
    });
  }, []);

  function donwloadBatchCSV() {
    setIsDownloading(true);

    let downloadUrl = "".concat(
      "/admin/batches/",
      props.batchId,
      "/download-csv"
    );

    if (props.isOverflow) {
      downloadUrl = "".concat(
        "/admin/batches/overflow/",
        props.batchId,
        "/download-csv"
      );
    }

    api_request(downloadUrl, "GET", {}, "blob").then(res => {
      fileDownload(res.data, "".concat("batch_", props.batchId, ".csv"));
      setIsDownloading(false);
    });
  }

  function downloadPreBatchCSV() {
    setIsDownloadingPreBatchCSV(true);

    let downloadUrl = "".concat(
      "/admin/batches/",
      props.batchId,
      "/download-pre-batch-csv"
    );

    if (props.isOverflow) {
      downloadUrl = "".concat(
        "/admin/batches/overflow/",
        props.batchId,
        "/download-pre-batch-csv"
      );
    }

    api_request(downloadUrl, "GET", {}, "blob").then(res => {
      fileDownload(res.data, "".concat("batch_", props.batchId, ".csv"));
      setIsDownloadingPreBatchCSV(false);
    });
  }

  function deleteBatch() {
    setSubmittingDelete(true);
    let deleteUrl = "".concat("/admin/batches/", props.batchId, "/delete");

    if (props.isOverflow) {
      deleteUrl = "".concat(
        "/admin/batches/overflow/",
        props.batchId,
        "/delete"
      );
    }

    api_request(deleteUrl, "DELETE", {}, "blob").then(res => {
      let data = res.data;
      if ("error" in data) {
        setShowDeleteModal(false);
        setSubmittingDelete(false);
        return;
      }

      navigate("/batches", {
        state: { deletedBatch: true }
      });
    });
  }

  return (
    <>
      <Breadcrumbs
        crumbs={{
          Batches: "/batches"
        }}
        title={props.isOverflow ? "Batch (overflow)" : "Batch (conversions)"}
      />

      <div className="box">
        <h4 className="title is-5">Batch Details</h4>
        {batch === null ? (
          <ContentLoader />
        ) : (
          <div className="columns">
            <div className="column is-narrow">
              <p>
                <b>Geo:</b>{" "}
                {batch.country_code
                  ? "".concat(flag(batch.country_code), " ", batch.country_name)
                  : "..."}
              </p>
              {!props.isOverflow ? (
                <p>
                  <b>Service:</b>{" "}
                  <ServiceFormatterNonTable
                    service={
                      batch.channel
                        ? "".concat(batch.service, " (", batch.channel, ")")
                        : batch.service
                    }
                  />
                </p>
              ) : null}
              <p>
                <b>Status:</b> {batch.status}
              </p>
              <p>
                <b>Created By:</b> {batch.microsoft_name}
              </p>
            </div>
            <div className="column is-narrow">
              <p>
                <b>Batch Time :</b> {batch.dt_created}
              </p>
              <p>
                <b>Batch Start Date:</b> {batch.dt_start}
              </p>
              <p>
                <b>Batch End Date:</b> {batch.dt_end}
              </p>
            </div>
            <div className="column">
              <p>
                <b>Error :</b> <code>{batch.error ? batch.error : "N/A"}</code>
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="box">
        <h4 className="title is-5">
          Batch Items{" "}
          {totalBatchItems ? "(".concat(totalBatchItems, ")") : null}{" "}
          <button
            className={
              "button is-primary " + (isDownloading ? "is-loading" : "")
            }
            onClick={donwloadBatchCSV}
            style={{
              verticalAlign: "baseline"
            }}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faDownload} />
            </span>
            <span> Download CSV</span>
          </button>
          &nbsp;
          <button
            className={
              "button is-primary " +
              (isDownloadingPreBatchCSV ? "is-loading" : "")
            }
            onClick={downloadPreBatchCSV}
            style={{
              verticalAlign: "baseline"
            }}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faDownload} />
            </span>
            <span> Download Pre-Batch CSV</span>
          </button>
          &nbsp;
          <button
            className={
              "button is-danger " +
              (isDownloadingPreBatchCSV ? "is-loading" : "")
            }
            onClick={() => setShowDeleteModal(true)}
            style={{
              verticalAlign: "baseline"
            }}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faSkull} />
            </span>
            <span> Delete Batch</span>
          </button>
        </h4>
        {tableData === null ? (
          <ContentLoader />
        ) : tableData.length > 0 ? (
          <ReactTabulator
            data={tableData}
            columns={columns}
            tooltips={true}
            options={{ layout: "fitColumns" }}
          />
        ) : (
          "No batch items to display."
        )}
      </div>

      <div className={`modal ${showDeleteModal ? "is-active" : null}`}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Delete Batch</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setShowDeleteModal(false)}
            />
          </header>
          <section className="modal-card-body">
            <div className="content">
              <p>Deleting a batch performs the following actions:</p>
              <ul>
                <li>Deletes all batch items.</li>
                <li>Deletes all conversions.</li>
                <li>Resets reports.</li>
                <li>
                  Postbacks will <b>NOT</b> be deleted (as they were more than
                  likely already posted). Be aware that a new upload <b>WILL</b>{" "}
                  trigger new postbacks.
                </li>
              </ul>
              <p>
                <b>Deleting a batch is irreversible.</b>
              </p>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              className={`button is-danger ${
                submittingDelete ? "is-loading" : null
              }`}
              onClick={deleteBatch}
            >
              Delete Batch
            </button>
            <button
              className="button"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Batch;
