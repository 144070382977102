// Formatters for react-select options.

import { flag } from "country-emoji";

/**
 * Formats a geo for a react-select option.
 * Geos come directly from the GET /admin/geos endpoint.
 * @param geo
 * @returns {{label: string, value: *}}
 * @constructor
 */
function GeoOptionFormatter(geo, isChannels) {
  if (isChannels) {
    return {
      value: geo.country_code,
      label: "".concat(flag(geo.country_code), " ", geo.country_name)
    };
  }

  return {
    value: geo.id,
    label: "".concat(
      flag(geo.country_code),
      " ",
      geo.country_name,
      " → ",
      geo.service.charAt(0).toUpperCase() + geo.service.slice(1),
      geo.channel ? " → " + geo.channel : ""
    )
  };
}

export { GeoOptionFormatter };
