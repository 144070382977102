import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import ChannelsAddEdit from "./ChannelsAddEdit";

const ChannelsAdd = () => {
  return (
    <>
      <Breadcrumbs
        crumbs={{
          Channels: "/channels"
        }}
        title="Add Channel"
      />
      <ChannelsAddEdit />
    </>
  );
};

export default ChannelsAdd;
