import React, { useEffect, useState } from "react";
import Campaign from "./Campaign";
import CampaignTabs from "./CampaignTabs";
import { api_request } from "./Helpers";
import ContentLoader from "./ContentLoader";
import Changelog from "./Changelog";
import DevNote from "./DevNote";

const CampaignChangelog = props => {
  const [logs, setLogs] = useState(null);
  useEffect(() => {
    api_request(
      "".concat("/admin/changelog/campaign/", props.campaignId),
      "GET"
    ).then(res => {
      const logData = res.data.changelog;

      if (logData.length > 0) {
        setLogs(logData);
      }
    });
  }, []);

  return (
    <>
      <Campaign campaignId={props.campaignId} />
      <CampaignTabs campaignId={props.campaignId} active="changelog" />
      <DevNote note="Lists all changes applied to this campaign." />

      {logs === null ? (
        "No logs available."
      ) : logs.length > 0 ? (
        <Changelog logs={logs} />
      ) : (
        <ContentLoader />
      )}
    </>
  );
};

export default CampaignChangelog;
