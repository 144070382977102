import React, { useState } from "react";
import {
  faCheck,
  faExclamationTriangle,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormValidationMessage from "./FormValidationMessage";
import { api_request } from "./Helpers";
import { navigate } from "@reach/router";
import Breadcrumbs from "./Breadcrumbs";

const AffiliatesAdd = () => {
  const [displayCakeAffiliateCard, setDisplayCakeAffiliateCard] = useState(
    false
  );

  // Cake Affiliate Card.
  const [affiliateName, setAffiliateName] = useState("");
  const [affiliateId, setAffiliateId] = useState(0);
  const [accountStatus, setAccountStatus] = useState("");
  const [accountManager, setAccountManager] = useState("");
  const [contacts, setContacts] = useState([]);
  const [paymentCurrency, setPaymentCurrency] = useState("");
  const [paymentSettingName, setPaymentSettingName] = useState("");
  const [addressStreet1, setAddressStreet1] = useState("");
  const [addressStreet2, setAddressStreet2] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [internal, setInternal] = useState(null);

  // For validation errors after retrieving CAKE details.
  const [cakeErrors, setCakeErrors] = useState([]);

  // Form errors.
  const errRequired = "Field is required.";

  // Form values.
  const [cakeId, updateCakeId] = useState("");

  // Validation messages.
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [backendError, setBackendError] = useState(null);

  const [isSearchingCake, setIsSearchingCake] = useState(false);

  function submit() {
    setIsFormSubmitted(true);

    let params = {
      cake_id: cakeId
    };

    api_request("/admin/affiliates", "POST", params).then(res => {
      let data = res.data;
      if ("error" in data) {
        setIsFormSubmitted(false);
        setBackendError(data["error"]);
        return;
      }

      navigate("/affiliates", {
        state: { newAffiliateId: data["affiliate_id"] }
      });
    });
  }

  // Search affiliate - first point of interaction.
  function searchAffiliate() {
    if (isSearchingCake) {
      return;
    }

    if (!cakeId) {
      return;
    }

    setIsSearchingCake(true);
    setBackendError([]);

    api_request("/admin/affiliates/cake/" + cakeId, "GET").then(res => {
      let data = res.data;
      if ("error" in data) {
        setIsSearchingCake(false);
        setDisplayCakeAffiliateCard(false);
        setBackendError(data["error"]);
        return;
      }

      setIsSearchingCake(false);

      // Set card details.
      setAffiliateName(data.affiliate_name);
      setAffiliateId(data.affiliate_id);
      setAccountStatus(data.account_status);
      setAccountManager(data.account_manager_name);
      setContacts(data.contacts);
      setPaymentCurrency(data.payment_currency);
      setPaymentSettingName(data.payment_setting_name);

      setAddressStreet1(data.address.street_1);
      setAddressStreet2(data.address.street_2);
      setAddressCity(data.address.city);
      setAddressState(data.address.state);
      setAddressZipCode(data.address.zip_code);
      setAddressCountry(data.address.country);
      setInternal(data.internal);

      let errors = [];
      if (!data.internal) {
        if (!data.payment_currency) {
          errors.push("Missing payment currency.");
        }

        if (!data.payment_setting_name) {
          errors.push("Missing payment setting.");
        }

        if (
          !data.address.street_1 ||
          !data.address.zip_code ||
          !data.address.country
        ) {
          errors.push(
            "Missing address details (street1, zip code, state or country)."
          );
        }
      }

      if (data.in_db) {
        errors.push("Affiliate already in the database.");
      }

      setCakeErrors(errors);
      setDisplayCakeAffiliateCard(true);
    });
  }

  return (
    <>
      <Breadcrumbs
        crumbs={{
          Affiliates: "/affiliates"
        }}
        title="Add Affiliate"
      />
      <div className="field">
        <div className="field-body">
          <div className="field has-addons">
            <div className="control">
              <input
                className="input"
                placeholder="CAKE Affiliate ID"
                type="number"
                value={cakeId}
                onChange={e => updateCakeId(e.target.value)}
              />
            </div>
            <div
              className="control"
              className="control is-expanded"
              onClick={searchAffiliate}
            >
              <a
                className={
                  "button is-info " + (isSearchingCake ? "is-loading" : "")
                }
              >
                Search
              </a>
            </div>
          </div>
        </div>
        <p className="help">
          All affiliates must first be generated in Cake before being imported
          into Azul.
        </p>
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <FormValidationMessage message={backendError} />
        {displayCakeAffiliateCard ? (
          <>
            <article
              className={
                "message " +
                (cakeErrors.length > 0 ? "is-danger" : "is-success")
              }
            >
              <div className="message-header">
                <p>Cake Affiliate API</p>
              </div>
              <div className="message-body">
                <div className="columns">
                  <div className="column">
                    <p>
                      <b>Affiliate Name:</b> {affiliateName} (#{affiliateId}){" "}
                      {internal ? (
                        <span className="tag is-primary">INTERNAL</span>
                      ) : (
                        ""
                      )}
                    </p>
                    <p>
                      <b>Status:</b> {accountStatus}{" "}
                      {accountStatus == "Inactive" ? (
                        <FontAwesomeIcon
                          color="red"
                          icon={faExclamationTriangle}
                        />
                      ) : (
                        <FontAwesomeIcon color="green" icon={faCheck} />
                      )}
                    </p>
                    <p>
                      <b>Account Manager:</b> {accountManager}
                    </p>
                  </div>
                  <div className="column">
                    <div>
                      <p>
                        <b>Street1:</b> {addressStreet1}
                      </p>
                      <p>
                        <b>Street2:</b> {addressStreet2}
                      </p>
                      <p>
                        <b>City:</b> {addressCity}
                      </p>
                      <p>
                        <b>State:</b> {addressState}
                      </p>
                      <p>
                        <b>Zip Code:</b> {addressZipCode}
                      </p>
                      <p>
                        <b>Country:</b> {addressCountry}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div>
                      <p>
                        <b>Payment Currency:</b> {paymentCurrency}
                      </p>
                      <p>
                        <b>Payment Setting:</b> {paymentSettingName}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    {contacts.map((contact, key) => {
                      return (
                        <div style={{ paddingBottom: "10px" }} key={key}>
                          <p>
                            <b>Contact #{key + 1}:</b>
                          </p>
                          <p>
                            <b>Full Name:</b> {contact.first_name}{" "}
                            {contact.last_name}
                          </p>
                          <p>
                            <b>Email:</b> {contact.email}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="columns is-centered">
                  <div className="column is-narrow has-text-centered">
                    {cakeErrors.map((error, key) => {
                      return (
                        <div style={{ marginBottom: "10px" }}>
                          <FormValidationMessage message={error} />
                        </div>
                      );
                    })}
                    <button
                      className={
                        "button is-primary " +
                        (isFormSubmitted ? "is-loading" : "")
                      }
                      disabled={cakeErrors.length > 0}
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                      <span>New Affiliate</span>
                    </button>
                  </div>
                </div>
              </div>
            </article>
          </>
        ) : null}
      </form>
    </>
  );
};

export default AffiliatesAdd;
