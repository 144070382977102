const errRequired = "Field is required.";
const errMissingTag = "Missing [tag] in URL.";
const errMissingTagFormat = "Missing [tag_id] in tag format.";
const errMissingMonetaryVal = "Value must be > 0.";

export {
  errRequired,
  errMissingTag,
  errMissingTagFormat,
  errMissingMonetaryVal
};
