import React, { useRef, useState } from "react";
import Breadcrumbs from "./Breadcrumbs";
import { api_request } from "./Helpers";
import ReportingForm from "./ReportingForm";
import ContentLoader from "./ContentLoader";
import { reactFormatter, ReactTabulator } from "react-tabulator";
import {
  ActionsFormatter,
  CampaignLabelFormatter,
  ServiceFormatter
} from "./Helpers/TableFormatters";
import { flag } from "country-emoji";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const Reporting = props => {
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);

  function downloadCsv() {
    // Downloads table data as CSV.
    // read https://github.com/ngduc/react-tabulator/issues/76
    const d = new Date();
    let dateStr = d.toISOString();
    tableRef.current.table.download("csv", "azul".concat("_", dateStr, ".csv"));
  }

  const tableOptions = {
    layout: "fitColumns",
    clipboard: true,
    downloadDataFormatter: data => data,
    downloadReady: (fileContents, blob) => blob
  };

  let tallyCallback = async () => {}; // Callback function for each type of reporting.

  let breadCrumbs = "";

  // Common "sum" columns for all tables.
  function commonSumCols(currencySymbol) {
    return [
      { title: "Clicks", field: "clicks", bottomCalc: "sum" },
      { title: "Conversions", field: "conversions", bottomCalc: "sum" },
      {
        title: "Received",
        field: "received",
        formatter: "money",
        formatterParams: { symbol: currencySymbol },
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {
          symbol: currencySymbol
        }
      },
      {
        title: "Payout",
        field: "payout",
        formatter: "money",
        formatterParams: { symbol: currencySymbol },
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {
          symbol: currencySymbol
        }
      },
      {
        title: "Gross Margin",
        field: "gross_margin",
        formatter: "money",
        formatterParams: { symbol: currencySymbol },
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {
          symbol: currencySymbol
        }
      },
      {
        title: "Conversion %",
        field: "conversion_percentage",
        formatter: "progress",
        formatterParams: {
          min: 0,
          max: 100,
          legend: function(value) {
            return value + "%";
          },
          color: ["#f19d9d", "#f1e79d", "#9df19d"],
          legendColor: "#000000",
          legendAlign: "center"
        }
      }
    ];
  }

  // Common "sum" columns non aggregated overflow values.
  function commonNonAggSumCols(currencySymbol) {
    return [
      {
        title: "Conversions Overflow",
        field: "conversions_overflow",
        bottomCalc: "sum"
      },
      {
        title: "Received Overflow",
        field: "received_overflow",
        formatter: "money",
        formatterParams: { symbol: currencySymbol },
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {
          symbol: currencySymbol
        }
      },
      {
        title: "Payout Overflow",
        field: "payout_overflow",
        formatter: "money",
        formatterParams: { symbol: currencySymbol },
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {
          symbol: currencySymbol
        }
      }
    ];
  }

  switch (props.section) {
    case "geos":
      breadCrumbs = (
        <Breadcrumbs
          crumbs={{
            Geos: "/geos"
          }}
          title="Reporting"
        />
      );

      tallyCallback = async (
        dateStart,
        dateEnd,
        currency,
        overflowAgg,
        account_manager_id,
        sales_person_id
      ) => {
        let params = {};
        if (account_manager_id) {
          params["account_manager_id"] = account_manager_id;
        }
        if (sales_person_id) {
          params["sales_person_id"] = sales_person_id;
        }
        api_request(
          "/admin/reporting/geos/".concat(
            dateStart,
            "/",
            dateEnd,
            "/",
            currency,
            "/",
            overflowAgg ? "overflow_aggregate" : "overflow_combine"
          ),
          "GET",
          params
        ).then(res => {
          const tally = res.data.tally;

          if (!tally.length) {
            setTableData(null);
            return;
          }

          const currencySymbol = res.data.currency_symbol;
          let tallyData = [];

          let cols = [
            { title: "Geo", field: "geo" },
            {
              title: "Service",
              field: "service",
              formatter: reactFormatter(<ServiceFormatter />)
            }
          ];

          cols = cols.concat(commonSumCols(currencySymbol));
          if (!overflowAgg) {
            cols = cols.concat(commonNonAggSumCols(currencySymbol));
          }

          setColumns(cols);

          for (let key in tally) {
            const row = tally[key];

            let tallyRow = {
              id: row.id,
              geo: "".concat(flag(row.country_code), " ", row.country_name),
              service: row.channel
                ? "".concat(row.service, " (", row.channel, ")")
                : row.service,
              clicks: row.clicks,
              conversions: row.conversions,
              received: row.received,
              payout: row.payout,
              conversion_percentage: row.conversion_percentage,
              gross_margin: row.received - row.payout
            };

            if (!overflowAgg) {
              tallyRow.conversions_overflow = row.conversions_overflow;
              tallyRow.received_overflow = row.received_overflow;
              tallyRow.payout_overflow = row.payout_overflow;
            }

            tallyData.push(tallyRow);
          }

          setTableData(tallyData);
        });
      };
      break;
    case "affiliates":
      breadCrumbs = (
        <Breadcrumbs
          crumbs={{
            Affiliates: "/affiliates"
          }}
          title="Reporting"
        />
      );

      tallyCallback = async (
        dateStart,
        dateEnd,
        currency,
        overflowAgg,
        geo_id,
        account_manager_id,
        sales_person_id
      ) => {
        let params = {};
        if (geo_id) {
          params["geo_id"] = geo_id;
        }
        if (account_manager_id) {
          params["account_manager_id"] = account_manager_id;
        }
        if (sales_person_id) {
          params["sales_person_id"] = sales_person_id;
        }

        api_request(
          "/admin/reporting/affiliates/".concat(
            dateStart,
            "/",
            dateEnd,
            "/",
            currency,
            "/",
            overflowAgg ? "overflow_aggregate" : "overflow_combine"
          ),
          "GET",
          params
        ).then(res => {
          const tally = res.data.tally;

          if (!tally.length) {
            setTableData(null);
            return;
          }

          const currencySymbol = res.data.currency_symbol;
          let tallyData = [];

          for (let key in tally) {
            const row = tally[key];

            let tallyRow = {
              id: row.id,
              affiliate: row.affiliate,
              clicks: row.clicks,
              conversions: row.conversions,
              received: row.received,
              payout: row.payout,
              conversion_percentage: row.conversion_percentage,
              gross_margin: row.received - row.payout
            };

            if (!overflowAgg) {
              tallyRow.conversions_overflow = row.conversions_overflow;
              tallyRow.received_overflow = row.received_overflow;
              tallyRow.payout_overflow = row.payout_overflow;
            }

            tallyData.push(tallyRow);
          }

          let cols = [{ title: "Affiliate", field: "affiliate" }];

          cols = cols.concat(commonSumCols(currencySymbol));

          if (!overflowAgg) {
            cols = cols.concat(commonNonAggSumCols(currencySymbol));
          }

          setColumns(cols);
          setTableData(tallyData);
        });
      };
      break;
    case "campaigns":
      breadCrumbs = (
        <Breadcrumbs
          crumbs={{
            Campaigns: "/campaigns"
          }}
          title="Reporting"
        />
      );

      tallyCallback = async (
        dateStart,
        dateEnd,
        currency,
        overflowAgg,
        geo_id,
        affiliate_id,
        account_manager_id,
        sales_person_id
      ) => {
        let params = {};
        if (geo_id) {
          params["geo_id"] = geo_id;
        }
        if (affiliate_id) {
          params["affiliate_id"] = affiliate_id;
        }
        if (account_manager_id) {
          params["account_manager_id"] = account_manager_id;
        }
        if (sales_person_id) {
          params["sales_person_id"] = sales_person_id;
        }

        api_request(
          "/admin/reporting/campaigns/".concat(
            dateStart,
            "/",
            dateEnd,
            "/",
            currency,
            "/",
            overflowAgg ? "overflow_aggregate" : "overflow_combine"
          ),
          "GET",
          params
        ).then(res => {
          const tally = res.data.tally;

          if (!tally.length) {
            setTableData(null);
            return;
          }

          const currencySymbol = res.data.currency_symbol;
          let tallyData = [];

          for (let key in tally) {
            const row = tally[key];

            let tallyRow = {
              id: row.id,
              external_id: row.external_id,
              geo: "".concat(flag(row.country_code), " ", row.country_name),
              service: row.channel
                ? "".concat(row.service, " (", row.channel, ")")
                : row.service,
              label: row.label,
              affiliate: row.affiliate,
              clicks: row.clicks,
              conversions: row.conversions,
              received: row.received,
              payout: row.payout,
              conversion_percentage: row.conversion_percentage,
              gross_margin: row.received - row.payout
            };

            if (!overflowAgg) {
              tallyRow.conversions_overflow = row.conversions_overflow;
              tallyRow.received_overflow = row.received_overflow;
              tallyRow.payout_overflow = row.payout_overflow;
            }

            tallyData.push(tallyRow);
          }

          let cols = [
            { title: "External ID", field: "external_id" },
            { title: "Geo", field: "geo" },
            {
              title: "Service",
              field: "service",
              formatter: reactFormatter(<ServiceFormatter />)
            },
            { title: "Affiliate", field: "affiliate" },
            {
              title: "Label",
              field: "label",
              formatter: reactFormatter(<CampaignLabelFormatter />),
              sorter: "alphanum"
            }
          ];

          cols = cols.concat(commonSumCols(currencySymbol));

          if (!overflowAgg) {
            cols = cols.concat(commonNonAggSumCols(currencySymbol));
          }

          cols = cols.concat({
            title: "",
            field: "actions",
            formatter: reactFormatter(
              <ActionsFormatter section="campaigns" viewOnly={true} />
            ),
            headerSort: false
          });

          setColumns(cols);
          setTableData(tallyData);
        });
      };

      break;
  }

  return (
    <>
      {breadCrumbs}
      <div className="box">
        <ReportingForm section={props.section} tallyCallback={tallyCallback} />
      </div>
      <div className="box">
        {tableData === null ? (
          "No reports available for the provided date(s)."
        ) : tableData.length > 0 ? (
          <div>
            <div className="buttons has-addons is-right">
              <button onClick={downloadCsv} className="button is-primary">
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faDownload} />
                </span>
                <span>Download CSV</span>
              </button>
            </div>
            <ReactTabulator
              ref={tableRef}
              data={tableData}
              columns={columns}
              tooltips={true}
              options={tableOptions}
            />
          </div>
        ) : (
          <ContentLoader />
        )}
      </div>
    </>
  );
};

export default Reporting;
