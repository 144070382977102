import Breadcrumbs from "./Breadcrumbs";
import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { reactFormatter, ReactTabulator } from "react-tabulator";
import { api_request } from "./Helpers";
import {
  ActionsFormatter,
  CampaignLabelFormatter,
  ServiceFormatter
} from "./Helpers/TableFormatters";
import { flag } from "country-emoji";
import ContentLoader from "./ContentLoader";
import { toast } from "react-toastify";

const Campaigns = props => {
  let tableRef = null;
  const columns = [
    {
      title: "External ID",
      field: "external_id",
      headerSort: false,
      headerFilter: "input"
    },
    {
      title: "Tag",
      field: "tag",
      headerSort: false,
      headerFilter: "input"
    },
    {
      title: "Geo",
      field: "geo",
      headerFilter: "select",
      headerFilterParams: { values: true }
    },
    {
      title: "Service",
      field: "service",
      formatter: reactFormatter(<ServiceFormatter />),
      headerFilter: "select",
      headerFilterParams: { values: true }
    },
    {
      title: "Affiliate",
      field: "affiliate_name",
      headerFilter: "input"
    },
    {
      title: "Label",
      field: "label",
      headerFilter: "input",
      formatter: reactFormatter(<CampaignLabelFormatter />)
    },
    {
      title: "Currency",
      field: "currency",
      headerFilter: "select",
      headerFilterParams: { values: true }
    },
    {
      title: "Received",
      field: "received",
      editor: "number",
      validator: [
        { type: validateMoneyCell, parameters: { field: "received" } }
      ]
    },
    {
      title: "Payout",
      field: "payout",
      editor: "number",
      validator: [{ type: validateMoneyCell, parameters: { field: "payout" } }]
    },
    {
      title: "Postback?",
      field: "postback",
      hozAlign: "center",
      width: 130,
      formatter: "tickCross"
    },
    {
      title: "",
      field: "actions",
      formatter: reactFormatter(<ActionsFormatter section="campaigns" />),
      headerSort: false
    }
  ];

  function validateMoneyCell(cell, newValue, params) {
    cell = cell._cell;
    const campaignId = cell.row.data.id;
    const externalId = cell.row.data.external_id;

    if (newValue === "") {
      toast.error(
        "".concat(
          "Campaign '",
          externalId,
          "' ",
          params.field,
          " value must be >=0."
        )
      );
      return false;
    }

    if (newValue === cell.initialValue) {
      return true;
    }

    api_request("/admin/campaigns/quick-edit", "POST", {
      campaign_id: campaignId,
      field: params.field,
      value: newValue
    }).then(res => {
      let data = res.data;

      if ("error" in data) {
        toast.error(
          "".concat(
            "Campaign '",
            externalId,
            "' failed to update! Contact a developer."
          )
        );
        // Revert value on the table so the user isn't confused.
        tableRef.table.updateRow(campaignId, {
          [params.field]: cell.initialValue
        });
        return false;
      }

      toast.success(
        "".concat("Campaign '", externalId, "' successfully updated!")
      );
    });

    return true;
  }

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    api_request("/admin/campaigns", "GET").then(res => {
      const campaigns = res.data.campaigns;
      let campaignsData = [];

      if (campaigns.length === 0) {
        setTableData(null);
        return;
      }

      for (let key in campaigns) {
        const campaign = campaigns[key];

        campaignsData.push({
          id: campaign.id,
          external_id: campaign.external_id,
          tag: campaign.tag,
          geo: "".concat(
            flag(campaign.country_code),
            " ",
            campaign.country_name
          ),
          label: campaign.label,
          service: campaign.channel
            ? "".concat(campaign.service, " (", campaign.channel, ")")
            : campaign.service,
          affiliate_name: campaign.affiliate_name,
          currency: campaign.currency,
          received: campaign.received,
          payout: campaign.payout,
          postback: !!campaign.postback_url
        });
      }
      setTableData(campaignsData);
    });
  }, []);

  const options = {
    layout: "fitColumns",
    pagination: "local",
    paginationSize: 20
  };

  return (
    <>
      <Breadcrumbs title="Campaigns" />
      {props.location.state && props.location.state.newCampaignId ? (
        <div className="notification is-info">
          New campaign added! Click{" "}
          <Link to={props.location.state.newCampaignId.toString()}>here</Link>{" "}
          to view it.
        </div>
      ) : null}
      {props.location.state && props.location.state.editCampaignId ? (
        <div className="notification is-info">
          Campaign has been successfully edited! Click{" "}
          <Link to={props.location.state.editCampaignId.toString()}>here</Link>{" "}
          to view it.
        </div>
      ) : null}
      <div className="buttons has-addons is-right">
        <Link to="add">
          <button className="button is-primary">
            <span className="icon is-small">
              <FontAwesomeIcon icon={faPlus} />
            </span>
            <span>Add Campaign</span>
          </button>
        </Link>
      </div>
      {tableData === null ? (
        "No campaigns available."
      ) : tableData.length > 0 ? (
        <ReactTabulator
          ref={ref => (tableRef = ref)}
          data={tableData}
          columns={columns}
          tooltips={true}
          options={options}
        />
      ) : (
        <ContentLoader />
      )}
    </>
  );
};

export default Campaigns;
