import Clipboard from "react-clipboard.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import Tippy from "@tippyjs/react";

function CopyLink(props) {
  // Element to present a copy link icon.
  let link = null;

  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const show = () => setVisibleTooltip(true);
  const hide = () => setVisibleTooltip(false);

  if (props.cell) {
    // Tabulator.
    link = props.cell._cell.row.data.link;
  } else {
    link = props.link;
  }
  return (
    <>
      <Clipboard
        component="span"
        data-clipboard-text={link}
        style={{ verticalAlign: "middle", cursor: "pointer" }}
        title="Copy link"
        onClick={visibleTooltip ? hide : show}
      >
        <Tippy content="Copied!" visible={visibleTooltip} onClickOutside={hide}>
          <span className="icon is-small">
            <FontAwesomeIcon icon={faCopy} />
          </span>
        </Tippy>
        <span className="is-size-7"> {link}</span>
      </Clipboard>
    </>
  );
}

export default CopyLink;
