import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import Campaign from "./Campaign";
import CampaignTabs from "./CampaignTabs";
import { api_request } from "./Helpers";
import {
  dateToString,
  formatMoney,
  iconFromCurrencyCode
} from "./Helpers/Formatters";
import ContentLoader from "./ContentLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import FormValidationMessage from "./FormValidationMessage";
import DevNote from "./DevNote";

const CampaignAdjustments = props => {
  const [conversions, setConversions] = useState([]);
  const [currency, setCurrency] = useState("");

  const [conversionType, setConversionType] = useState("");

  const [oldConversions, setOldConversions] = useState(0);
  const [oldReceived, setOldReceived] = useState(0);
  const [oldPayout, setOldPayout] = useState(0);

  const [newConversions, setNewConversions] = useState(0);
  const [newReceived, setNewReceived] = useState(0);
  const [newPayout, setNewPayout] = useState(0);

  const [date, setDate] = useState(new Date());

  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [backendError, setBackendError] = useState("");

  useEffect(() => {
    getConversions(date);
  }, [date]);

  function getConversions(dateVal) {
    setConversions([]);
    api_request(
      "".concat(
        "/admin/conversions/",
        props.campaignId,
        "/",
        dateToString(dateVal)
      ),
      "GET"
    ).then(res => {
      const conversions = res.data.conversions;
      setCurrency(res.data.currency);

      if (conversions.length > 0) {
        setConversions(conversions);
      } else {
        setConversions(null);
      }
    });
  }

  function editConversion(conversion) {
    setConversionType(conversion.type);
    setOldConversions(conversion.conversions);
    setOldReceived(conversion.received_conversion);
    setOldPayout(conversion.payout_conversion);

    setNewConversions(conversion.conversions);
    setNewReceived(conversion.received_conversion);
    setNewPayout(conversion.payout_conversion);

    setShowModal(true);
  }

  function submit() {
    setSubmitting(true);
    setBackendError("");

    api_request("/admin/conversions", "POST", {
      campaign_id: props.campaignId,
      date: dateToString(date),
      type: conversionType,
      conversions: newConversions,
      received: newReceived,
      payout: newPayout
    }).then(res => {
      let data = res.data;

      if ("error" in data) {
        setBackendError(data["error"]);
      }

      setShowModal(false);
      setSubmitting(false);
      getConversions(date);
    });
  }

  return (
    <>
      <Campaign campaignId={props.campaignId} />
      <CampaignTabs campaignId={props.campaignId} active="adjustments" />
      <DevNote note="Allows you to ajust conversions and received/payout values for a single date. Typically used in 'end of the month' adjustments." />

      <label className="label">Select Date</label>
      <Flatpickr className="input" value={date} onChange={e => setDate(e[0])} />
      <FormValidationMessage message={backendError} />

      {conversions === null ? (
        "No conversions available."
      ) : conversions.length > 0 ? (
        <table className="table is-striped is-fullwidth">
          <tr>
            <th>Conversion Type</th>
            <th>Conversions</th>
            <th>Received Rate</th>
            <th>Payout Rate</th>
            <th></th>
          </tr>
          <tbody>
            {conversions.map((conversion, key) => {
              return (
                <tr key={key}>
                  <td>{conversion.type}</td>
                  <td>{conversion.conversions}</td>
                  <td>
                    {currency
                      ? formatMoney(currency, conversion.received_conversion)
                      : null}
                  </td>
                  <td>
                    {currency
                      ? formatMoney(currency, conversion.payout_conversion)
                      : null}
                  </td>
                  <td>
                    <button
                      className="button is-primary"
                      onClick={() => editConversion(conversion)}
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </span>
                      <span>Edit</span>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <ContentLoader />
      )}
      <div className={`modal ${showModal ? "is-active" : null}`}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Adjust Conversions</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setShowModal(false)}
            />
          </header>
          <section className="modal-card-body">
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <td></td>
                  <td>Original Value</td>
                  <td>New Value</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Conversions</b>
                  </td>
                  <td>{oldConversions}</td>
                  <td>
                    <input
                      className="input"
                      type="number"
                      value={newConversions}
                      onChange={e => setNewConversions(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Received</b>
                  </td>
                  <td>
                    {currency ? formatMoney(currency, oldReceived) : null}
                  </td>
                  <td>
                    <p className="control is-expanded has-icons-left">
                      <input
                        className="input"
                        type="number"
                        value={newReceived}
                        onChange={e => setNewReceived(e.target.value)}
                      />
                      <span className="icon is-small is-left">
                        {iconFromCurrencyCode(currency)}
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Payout</b>
                  </td>
                  <td>{currency ? formatMoney(currency, oldPayout) : null}</td>
                  <td>
                    <p className="control is-expanded has-icons-left">
                      <input
                        className="input"
                        type="number"
                        value={newPayout}
                        onChange={e => setNewPayout(e.target.value)}
                      />
                      <span className="icon is-small is-left">
                        {iconFromCurrencyCode(currency)}
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Adjusting <b>{conversionType}</b> conversions for{" "}
              <b>{dateToString(date)}</b>.
            </p>
          </section>
          <footer className="modal-card-foot">
            <button
              className={`button is-success ${
                submitting ? "is-loading" : null
              }`}
              onClick={submit}
            >
              Save Changes
            </button>
            <button className="button" onClick={() => setShowModal(false)}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default CampaignAdjustments;
