import React, { useEffect, useState } from "react";
import { reactFormatter, ReactTabulator } from "react-tabulator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "@reach/router";
import Breadcrumbs from "./Breadcrumbs";
import { api_request } from "./Helpers";
import { flag } from "country-emoji";
import ContentLoader from "./ContentLoader";
import { ActionsFormatter, ServiceFormatter } from "./Helpers/TableFormatters";
import { formatMoney } from "./Helpers/Formatters";

const Geos = props => {
  const columns = [
    { title: "Geo", field: "geo" },
    {
      title: "Service",
      field: "service",
      formatter: reactFormatter(<ServiceFormatter />)
    },
    { title: "Default Currency", field: "currency" },
    { title: "Default Received", field: "default_received", headerSort: false },
    { title: "Default Payout", field: "default_payout", headerSort: false },
    {
      title: "",
      field: "actions",
      formatter: reactFormatter(<ActionsFormatter section="geos" />),
      headerSort: false
    }
  ];

  const options = {
    layout: "fitColumns"
  };

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    api_request("/admin/geos", "GET").then(res => {
      const geos = res.data.geos;
      let geoData = [];

      if (geos.length === 0) {
        setTableData(null);
        return;
      }

      for (let key in geos) {
        const geo = geos[key];

        geoData.push({
          id: geo.id,
          geo: "".concat(flag(geo.country_code), " ", geo.country_name),
          service: geo.channel
            ? "".concat(geo.service, " (", geo.channel, ")")
            : geo.service,
          currency: geo.default_currency,
          default_received: formatMoney(
            geo.default_currency,
            geo.default_received
          ),
          default_payout: formatMoney(geo.default_currency, geo.default_payout)
        });
      }
      setTableData(geoData);
    });
  }, []);

  return (
    <>
      <Breadcrumbs title="Geos" />
      {props.location.state && props.location.state.newGeoID ? (
        <div className="notification is-info">
          New Geo & Amazon Services added!
        </div>
      ) : null}
      {props.location.state && props.location.state.geoEdited ? (
        <div className="notification is-info">
          Geo has been successfully edited!
        </div>
      ) : null}
      <div className="buttons has-addons is-right">
        <Link to="add">
          <button className="button is-primary">
            <span className="icon is-small">
              <FontAwesomeIcon icon={faPlus} />
            </span>
            <span> New Geo</span>
          </button>
        </Link>
      </div>
      {tableData === null ? (
        "No Geos available."
      ) : tableData.length > 0 ? (
        <ReactTabulator
          data={tableData}
          columns={columns}
          tooltips={true}
          options={options}
        />
      ) : (
        <ContentLoader />
      )}
    </>
  );
};

export default Geos;
