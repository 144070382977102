import React, { useEffect, useState } from "react";
import { api_request } from "./Helpers";
import Breadcrumbs from "./Breadcrumbs";
import ContentLoader from "./ContentLoader";
import { flag } from "country-emoji";
import { ServiceFormatterNonTable } from "./Helpers/TableFormatters";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@reach/router";
import { formatMoney } from "./Helpers/Formatters";

const Geo = props => {
  const [geo, setGeo] = useState(null);

  useEffect(() => {
    api_request("/admin/geos/".concat(props.geoId), "GET").then(res => {
      setGeo(res.data.geo);
    });
  }, []);

  return (
    <>
      <Breadcrumbs
        crumbs={{
          Campaigns: "/geos"
        }}
        title="Geo"
      />
      <div className="box">
        {geo === null ? (
          <ContentLoader />
        ) : (
          <>
            <div className="columns">
              <div className="column is-narrow">
                <p>
                  <b>Geo:</b>{" "}
                  {"".concat(flag(geo.country_code), " ", geo.country_name)}
                </p>
                <p>
                  <b>Service:</b>{" "}
                  <ServiceFormatterNonTable
                    service={
                      geo.channel
                        ? "".concat(geo.service, " (", geo.channel, ")")
                        : geo.service
                    }
                  />
                </p>
                <p>
                  <b>Base URL:</b> {geo.base_url}
                </p>
                <p>
                  <b>Tag Format:</b> {geo.tag_format}
                </p>
                <p>
                  <Link to={"/geos/edit/".concat(geo.id)}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </Link>
                </p>
              </div>
              <div className="column is-narrow">
                <p>
                  <b>Currency:</b> {geo.default_currency}
                </p>
                <p>
                  <b>Default Received:</b>{" "}
                  {formatMoney(geo.default_currency, geo.default_received)}
                </p>
                <p>
                  <b>Default Payout:</b>{" "}
                  {formatMoney(geo.default_currency, geo.default_payout)}
                </p>
              </div>
              <div className="column is-narrow">
                <p>
                  <b>Contact Name:</b>{" "}
                  {geo.contact_name ? geo.contact_name : "N/A"}
                </p>
                <p>
                  <b>Contact Email:</b>{" "}
                  {geo.contact_email ? geo.contact_email : "N/A"}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Geo;
