import React from "react";
import { Link } from "@reach/router";

const CampaignTabs = props => {
  return (
    <div className="tabs is-boxed">
      <ul>
        {/*<li className={props.active === "reports" ? "is-active" : null}>*/}
        {/*  <Link to={"".concat("/campaigns/", props.campaignId, "/reports")}>*/}
        {/*    <span>Reports</span>*/}
        {/*  </Link>*/}
        {/*</li>*/}
        <li className={props.active === "adjustments" ? "is-active" : null}>
          <Link to={"".concat("/campaigns/", props.campaignId, "/adjustments")}>
            <span>Adjustments</span>
          </Link>
        </li>
        <li className={props.active === "changelog" ? "is-active" : null}>
          <Link to={"".concat("/campaigns/", props.campaignId, "/changelog")}>
            <span>Changelog</span>
          </Link>
        </li>
        <li className={props.active === "rates" ? "is-active" : null}>
          <Link to={"".concat("/campaigns/", props.campaignId, "/rates")}>
            <span>Rate Changes</span>
          </Link>
        </li>
        {/*<li className={props.active === "clicks" ? "is-active" : null}>*/}
        {/*  <a>*/}
        {/*    <span>Clicks</span>*/}
        {/*  </a>*/}
        {/*</li>*/}
        {/*<li className={props.active === "conversions" ? "is-active" : null}>*/}
        {/*  <a>*/}
        {/*    <span>Conversions</span>*/}
        {/*  </a>*/}
        {/*</li>*/}
      </ul>
    </div>
  );
};

export default CampaignTabs;
