import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import CampaignsAddEdit from "./CampaignsAddEdit";

const CampaignsAdd = () => {
  return (
    <>
      <Breadcrumbs
        crumbs={{
          Campaigns: "/campaigns"
        }}
        title="Add Campaign"
      />
      <CampaignsAddEdit />
    </>
  );
};

export default CampaignsAdd;
