import React, { useEffect, useState } from "react";
import { api_request } from "./Helpers";
import ContentLoader from "./ContentLoader";
import Changelog from "./Changelog";
import Affiliate from "./Affiliate";
import AffiliateTabs from "./AffiliateTabs";

const AffiliateChangelog = props => {
  const [logs, setLogs] = useState(null);
  useEffect(() => {
    api_request(
      "".concat("/admin/changelog/affiliate/", props.affiliateId),
      "GET"
    ).then(res => {
      const logData = res.data.changelog;

      if (logData.length > 0) {
        setLogs(logData);
      }
    });
  }, []);

  return (
    <>
      <Affiliate affiliateId={props.affiliateId} />
      <AffiliateTabs affiliateId={props.affiliateId} active="changelog" />

      {logs === null ? (
        "No logs available."
      ) : logs.length > 0 ? (
        <Changelog logs={logs} />
      ) : (
        <ContentLoader />
      )}
    </>
  );
};

export default AffiliateChangelog;
