import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faPlus,
  faPoundSign,
  faTag
} from "@fortawesome/free-solid-svg-icons";
import { api_request } from "./Helpers";
import { flag } from "country-emoji";
import FormValidationMessage from "./FormValidationMessage";
import {
  errMissingMonetaryVal,
  errMissingTag,
  errRequired
} from "./Helpers/FormValidation";
import { navigate } from "@reach/router";
import { GeoOptionFormatter } from "./Helpers/SelectFormatters";

const CampaignsAddEdit = props => {
  // Disabled status for fields that require previous input from other fields.
  const [isCurrenciesDisabled, setIsCurrenciesDisabled] = useState(true);
  const [isReceivedDisabled, setIsReceivedDisabled] = useState(true);
  const [isPayoutDisabled, setIsPayoutDisabled] = useState(true);

  // Form values.
  const [geo, updateGeo] = useState("");
  const [currency, updateCurrency] = useState("");
  const [affiliate, updateAffiliate] = useState("");
  const [trafficType, updateTrafficType] = useState("");
  const [received, updateReceived] = useState(0);
  const [payout, updatePayout] = useState(0);
  const [payoutOverflow, updatePayoutOverflow] = useState(0);
  const [receivedOverflow, updateReceivedOverflow] = useState(0);
  const [notes, updateNotes] = useState("");
  const [postbackURL, updatePostbackURL] = useState("");
  const [label, updateLabel] = useState("");
  const [baseURL, updateBaseURL] = useState("");

  // Ajax values.
  const [geos, setGeos] = useState({});

  // Select options.
  const [geoOptions, setGeoOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [affiliateOptions, setAffiliateOptions] = useState([]);
  // Traffic type list for select field.
  const trafficTypes = [
    { value: "incentive", label: "incentive" },
    { value: "non-incentive", label: "non-incentive" },
    { value: "media-buying", label: "media-buying" },
    { value: "pop", label: "pop" },
    { value: "push", label: "push" },
    { value: "native", label: "native" },
    { value: "display", label: "display" },
    { value: "content", label: "content" },
    { value: "offer-wall", label: "offer-wall" },
    { value: "search", label: "search" }
  ];

  const [geoSelectValue, setGeoSelectValue] = useState(null);
  const [affiliateSelectValue, setAffiliateSelectValue] = useState(null);
  const [trafficTypeSelectValue, setTrafficTypeSelectValue] = useState(null);

  const [currencyOptionDict, setCurrencyOptionDict] = useState([]); // Lookup by value for react-select.

  const [currencySelectValue, setCurrencySelectValue] = useState(null);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [isChannel, setIsChannel] = useState(false);

  // Validation messages.
  const [geoValidationMsg, setGeoValidationMsg] = useState(null);
  const [currencyValidationMsg, setCurrencyValidationMsg] = useState(null);
  const [affiliateValidationMsg, setAffiliateValidationMsg] = useState(null);
  const [receivedValidatonMsg, setReceivedValidationMsg] = useState(null);
  const [payoutValidationMsg, setPayoutValidationMsg] = useState(null);
  const [labelValidationMsg, setlabelValidationMsg] = useState(null);
  const [baseURLValidationMsg, setBaseURLValidationMsg] = useState(null);
  // For any errors returned by the backend.
  const [backendError, setBackendError] = useState(null);

  function handleGeoChange(selectedGeo) {
    const geo = geos[selectedGeo.value];
    // Form value.
    updateGeo(selectedGeo.value);
    // react-select value.
    setGeoSelectValue(selectedGeo);

    // Set form values to default value for geo.
    updateCurrency(geo.default_currency);
    // Needed for react-select.
    setCurrencySelectValue(currencyOptionDict[geo.default_currency]);
    setIsCurrenciesDisabled(false);
    updateReceived(geo.default_received);
    setIsReceivedDisabled(false);
    updatePayout(geo.default_payout);
    updatePayout(geo.default_payout);
    setIsPayoutDisabled(false);

    updateReceivedOverflow(geo.default_received_overflow);
    updatePayoutOverflow(geo.default_payout_overflow);

    // Show overflow fields for channels.
    if (geo.service === "channels") {
      setIsChannel(true);
    } else {
      setIsChannel(false);
    }
  }

  function handleCurrencyChange(selectedCurrency) {
    updateCurrency(selectedCurrency.value);
    setCurrencySelectValue(selectedCurrency);
  }

  function handleAffiliateChange(selectedAffiliate) {
    updateAffiliate(selectedAffiliate.value);
    setAffiliateSelectValue(selectedAffiliate);
  }

  function handleTrafficTypeChange(selectedTrafficType) {
    updateTrafficType(selectedTrafficType.value);
    setTrafficTypeSelectValue(selectedTrafficType);
  }

  useEffect(() => {
    // Edit?
    if (props.campaignId) {
      api_request("/admin/campaigns/".concat(props.campaignId), "GET").then(
        res => {
          const campaign = res.data.campaign;

          updateGeo(campaign.geo_id);
          setGeoSelectValue({
            value: campaign.geo_id,
            label: "".concat(
              flag(campaign.country_code),
              " ",
              campaign.country_name,
              " (",
              campaign.service.charAt(0).toUpperCase() +
                campaign.service.slice(1),
              ")"
            )
          });

          updateAffiliate(campaign.affiliate_id);
          setAffiliateSelectValue({
            value: campaign.affiliate_id,
            label: "".concat(
              "(#",
              campaign.affiliate_cake_id,
              ") ",
              campaign.affiliate_name
            )
          });

          updateCurrency(campaign.currency);
          setCurrencySelectValue({
            value: campaign.currency,
            label: campaign.currency_name
          });

          updateReceived(campaign.received);
          setIsReceivedDisabled(false);
          updatePayout(campaign.payout);
          setIsPayoutDisabled(false);
          updateReceivedOverflow(campaign.received_overflow);
          updatePayoutOverflow(campaign.payout_overflow);
          updateLabel(campaign.label ? campaign.label : "");
          updateBaseURL(campaign.base_url ? campaign.base_url : "");

          setIsChannel(campaign.service == "channels");

          updateTrafficType(campaign.traffic_type);
          setTrafficTypeSelectValue({
            value: campaign.traffic_type,
            label: campaign.traffic_type
          });

          updateNotes(campaign.notes);

          if (campaign.postback_url) {
            updatePostbackURL(campaign.postback_url);
          }
        }
      );

      return;
    }

    // Get geos.
    api_request("/admin/geos", "GET").then(res => {
      let geosAjax = res.data.geos;
      let geosAjaxDict = {};
      let geoOptionsAjax = [];

      for (let key in geosAjax) {
        const geo = geosAjax[key];

        const option = GeoOptionFormatter(geo);

        geoOptionsAjax.push(option);
        geosAjaxDict[geo.id] = geo;
      }
      setGeoOptions(geoOptionsAjax);
      setGeos(geosAjaxDict);
    });

    // Get affiliates.
    api_request("/admin/affiliates", "GET").then(res => {
      let affiliates = res.data.affiliates;
      let affiliatesSel = [];
      let affiliatesSelDict = {}; // Required to easily set the value on edit.

      for (let key in affiliates) {
        const affiliate = affiliates[key];

        const selVal = {
          value: affiliate.id,
          label: "".concat("(#", affiliate.cake_id, ") ", affiliate.name)
        };

        affiliatesSel.push(selVal);
        affiliatesSelDict[affiliate.id] = selVal;
      }
      setAffiliateOptions(affiliatesSel);
    });

    // Get currencies.
    api_request("/admin/currencies", "GET").then(res => {
      let currenciesAjax = res.data.currencies;
      let currencyOptionsAjax = [];
      let currencyOptionDictAjax = {};

      for (let key in currenciesAjax) {
        const currency = currenciesAjax[key];

        const option = {
          value: currency.iso_code,
          label: currency.name
        };

        currencyOptionsAjax.push(option);
        currencyOptionDictAjax[currency.iso_code] = option;
      }

      setCurrencyOptions(currencyOptionsAjax);
      setCurrencyOptionDict(currencyOptionDictAjax);
    });
  }, []);

  function submit() {
    // Form submission.
    setIsFormSubmitted(true);

    let failedValidation = false;

    setGeoValidationMsg(null);
    setAffiliateValidationMsg(null);
    setCurrencyValidationMsg(null);
    setReceivedValidationMsg(null);
    setPayoutValidationMsg(null);
    setlabelValidationMsg(null);
    setBaseURLValidationMsg(null);

    if (geo === "") {
      failedValidation = true;
      setGeoValidationMsg(errRequired);
    }

    if (affiliate === "") {
      failedValidation = true;
      setAffiliateValidationMsg(errRequired);
    }

    if (currency === "") {
      failedValidation = true;
      setCurrencyValidationMsg(errRequired);
    }

    if (received <= 0) {
      failedValidation = true;
      setReceivedValidationMsg(errMissingMonetaryVal);
    }

    if (baseURL !== "" && !baseURL.includes("[tag]")) {
      failedValidation = true;
      setBaseURLValidationMsg(errMissingTag);
    }

    const labelLimit = 50;
    if (label.length > labelLimit) {
      failedValidation = true;
      setlabelValidationMsg(
        "".concat(
          "Label length must be <= ",
          labelLimit,
          " characters. ",
          label.length,
          " characters provided."
        )
      );
    }

    if (failedValidation) {
      setIsFormSubmitted(false);
      return;
    }

    let params = {
      geo_id: geo,
      affiliate_id: affiliate,
      currency: currency,
      received: received,
      payout: payout,
      received_overflow: receivedOverflow,
      payout_overflow: payoutOverflow
    };

    if (props.campaignId) {
      params.campaign_id = props.campaignId;
    }

    if (notes) {
      params.notes = notes;
    }

    if (trafficType) {
      params.traffic_type = trafficType;
    }

    if (postbackURL) {
      params.postback_url = postbackURL;
    }

    if (label) {
      params.label = label;
    }

    if (baseURL) {
      params.base_url = baseURL;
    }

    api_request("/admin/campaigns", "POST", params).then(res => {
      let data = res.data;
      if ("error" in data) {
        setIsFormSubmitted(false);
        setBackendError(data["error"]);
        return;
      }

      if (props.campaignId) {
        navigate("/campaigns", {
          state: { editCampaignId: data["campaign_id"] }
        });
      } else {
        navigate("/campaigns", {
          state: { newCampaignId: data["campaign_id"] }
        });
      }
    });
  }

  return (
    <>
      <p>
        <b>Note:</b> Links will automatically be generated as part of creating a
        new campaign.
      </p>
      <br />
      <form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Geo</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Select
                isClearable={true}
                options={geoOptions}
                onChange={handleGeoChange}
                value={geoSelectValue}
                isDisabled={!!props.campaignId}
              />
              <FormValidationMessage message={geoValidationMsg} />
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Affiliate</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Select
                isClearable={true}
                options={affiliateOptions}
                onChange={handleAffiliateChange}
                value={affiliateSelectValue}
                isDisabled={!!props.campaignId}
              />
              <FormValidationMessage message={affiliateValidationMsg} />
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Currency</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Select
                isClearable={true}
                isDisabled={isCurrenciesDisabled}
                options={currencyOptions}
                onChange={handleCurrencyChange}
                value={currencySelectValue}
              />
              <FormValidationMessage message={currencyValidationMsg} />
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Received</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="number"
                  step=".01"
                  disabled={isReceivedDisabled}
                  value={received}
                  onChange={e => updateReceived(e.target.value)}
                />
                <FormValidationMessage message={receivedValidatonMsg} />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faPoundSign} />
                </span>
              </p>
              <p className="help">How much we are paid per conversion.</p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Payout</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="number"
                  step=".01"
                  disabled={isPayoutDisabled}
                  value={payout}
                  onChange={e => updatePayout(e.target.value)}
                />
                <FormValidationMessage message={payoutValidationMsg} />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faPoundSign} />
                </span>
              </p>
              <p className="help">
                How much we pay the affiliate per conversion.
              </p>
            </div>
          </div>
        </div>

        {isChannel ? (
          <>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Received Overflow</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="number"
                      step=".01"
                      disabled={isReceivedDisabled}
                      value={receivedOverflow}
                      onChange={e => updateReceivedOverflow(e.target.value)}
                    />
                    <FormValidationMessage message={receivedValidatonMsg} />
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={faPoundSign} />
                    </span>
                  </p>
                  <p className="help">
                    How much we are paid per overflow conversion.
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Payout Overflow</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="number"
                      step=".01"
                      disabled={isPayoutDisabled}
                      value={payoutOverflow}
                      onChange={e => updatePayoutOverflow(e.target.value)}
                    />
                    <FormValidationMessage message={payoutValidationMsg} />
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={faPoundSign} />
                    </span>
                  </p>
                  <p className="help">
                    How much we pay the affiliate per overflow conversion.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : null}

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Amazon Base URL</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  value={baseURL}
                  onChange={e => updateBaseURL(e.target.value)}
                  placeholder="https://amazon.com?ref=[tag]"
                />
                <FormValidationMessage message={baseURLValidationMsg} />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faLink} />
                </span>
                <p className="help">
                  The destination URL for this Amazon service. [tag] is replaced
                  by the actual campaign tag. <b>Overrides the Geo Base URL.</b>
                </p>
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Postback URL</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  value={postbackURL}
                  onChange={e => updatePostbackURL(e.target.value)}
                  placeholder="https://postback.com?id=[s2]"
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faLink} />
                </span>
                <p className="help">
                  You can use GET parameters (such as s2) from the click URL to
                  pass data back in the Postback URL.{" "}
                  <b>Overrides the Affiliate Postback URL.</b>
                </p>
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Label</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  value={label}
                  onChange={e => updateLabel(e.target.value)}
                  placeholder="Short 'n' Sweet please..."
                />
                <FormValidationMessage message={labelValidationMsg} />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faTag} />
                </span>
                <p className="help">
                  Use this to label the campaign in our various reports. Try to
                  keep it simple please!
                </p>
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Traffic Type</label>
          </div>
          <div className="field-body">
            <div className="field">
              <>
                <Select
                  options={trafficTypes}
                  onChange={handleTrafficTypeChange}
                  value={trafficTypeSelectValue}
                />
              </>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Notes</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <textarea
                  className="textarea"
                  value={notes}
                  onChange={e => updateNotes(e.target.value)}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label" />
          <div className="field-body">
            <div className="field">
              <FormValidationMessage message={backendError} />
              <div className="control">
                <button
                  className={
                    "button is-primary " + (isFormSubmitted ? "is-loading" : "")
                  }
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                  <span>
                    {props.campaignId ? "Edit Campaign" : "New Campaign"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CampaignsAddEdit;
