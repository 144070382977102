import React, { useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ReactTabulator } from "react-tabulator";
import { navigate } from "@reach/router";

const Search = () => {
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  function handleShowSearchModal() {
    if (showSearchModal === true) {
      setSearchVal("");
    }
    setShowSearchModal(!showSearchModal);
  }

  function search(e) {
    if (e.key === "Enter") {
      setShowSearchModal(true);
    }
  }

  const columns = [
    {
      title: "Type",
      field: "type",
      editor: "input",
      headerFilter: "select",
      headerFilterParams: { values: true }
    },
    { title: "ID", field: "id" },
    { title: "Name", field: "name" }
  ];

  var data = [
    {
      id: 1,
      type: "Service",
      name: "UK Prime"
    },
    {
      id: 2,
      type: "Service",
      name: "UK Video"
    },
    {
      id: 3,
      type: "Service",
      name: "FR Prime"
    },
    {
      id: 4,
      type: "Offer",
      name: "UK Prime (r: £18/p: £10)"
    },
    {
      id: 5,
      type: "Affiliate",
      name: "Affiliator"
    },
    {
      id: 6,
      type: "Tag",
      name: "dvm_uk_ass_mae_CPA9_8_15"
    },
    {
      id: 7,
      type: "Tag",
      name: "dvm_uk_ass_mae_CPA42_8_15-21"
    }
  ];

  const options = {
    layout: "fitDataStretch",
    rowClick: function(e, row) {
      let page = row._row.data.type.toLowerCase();
      let id = row._row.data.id;
      let urlParams = ["/", page, "/", id];
      let url = "".concat(...urlParams);
      handleShowSearchModal();
      navigate(url);
    }
  };

  return (
    <>
      <div className="control has-icons-left has-icons-right">
        <input
          className="input is-medium"
          type="email"
          placeholder="Search..."
          value={searchVal}
          onChange={e => setSearchVal(e.target.value)}
          onKeyUp={search}
        />
        <span className="icon is-medium is-left">
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </div>
      {showSearchModal ? (
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                Searching for <b>{searchVal}</b>
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={handleShowSearchModal}
              />
            </header>
            <section className="modal-card-body">
              <ReactTabulator
                data={data}
                columns={columns}
                tooltips={true}
                layout={"fitDataStretch"}
                options={options}
              />
            </section>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={handleShowSearchModal}
          />
        </div>
      ) : null}
    </>
  );
};

export default Search;
