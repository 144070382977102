import React from "react";
import { Link } from "@reach/router";

const GeoTabs = props => {
  return (
    <div className="tabs is-boxed">
      <ul>
        {/*        <li className={props.active === "reports" ? "is-active" : null}>
          <Link to={"".concat("/geos/", props.geoId, "/reports")}>
            <span>Reports</span>
          </Link>
        </li>*/}
        <li className={props.active === "changelog" ? "is-active" : null}>
          <Link to={"".concat("/geos/", props.geoId, "/changelog")}>
            <span>Changelog</span>
          </Link>
        </li>
        {/*<li className={props.active === "clicks" ? "is-active" : null}>
          <a>
            <span>Clicks</span>
          </a>
        </li>
        <li className={props.active === "conversions" ? "is-active" : null}>
          <a>
            <span>Conversions</span>
          </a>
        </li>*/}
      </ul>
    </div>
  );
};

export default GeoTabs;
