import axios from "axios";

export const api_request = (endpoint, method, params, responseType) => {
  if (!method) {
    method = "GET";
  }

  if (!params) {
    params = {};
  }

  if (!responseType) {
    responseType = "json";
  }

  const url = process.env.API_URL + endpoint;

  const formData = new FormData();

  for (const [key, value] of Object.entries(params)) {
    formData.append(key, value);
  }

  return axios({
    method: method,
    url: url,
    responseType: responseType,
    withCredentials: true,
    headers:
      method === "POST"
        ? { "content-type": "application/x-www-form-urlencoded" }
        : null,
    params: method === "GET" && params ? params : null,
    data: formData,
    validateStatus: function(status) {
      return status >= 200 && status < 500;
    }
  })
    .then(res => {
      if (
        endpoint !== "/admin/users/me" &&
        "error" in res.data &&
        res.data["error"] === "not logged in"
      ) {
        // Refresh page entirely - this should effectively trigger a login.
        // We don't want to do this on the /me check as that can cause the app to infinite loop.
        location.reload();
      }

      return res;
    })
    .catch(function(error) {
      console.log("api call error:")
      console.log(error);
    });
};
