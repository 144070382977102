import React, { useEffect, useState } from "react";
import { api_request } from "./Helpers";
import Breadcrumbs from "./Breadcrumbs";
import ContentLoader from "./ContentLoader";
import { ReactTabulator } from "react-tabulator";
import { formatMoney } from "./Helpers/Formatters";

const BatchItem = props => {
  const [batchItem, setBatchItem] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [howManyToRetry, setHowManyToRetry] = useState(false);
  const [isRetrying, setIsRetrying] = useState(null);

  let batchItemUrl = "/admin/batches/item/";
  let batchItemPostbacksUrl = "/admin/batches/item/postbacks/";
  if (props.isOverflow) {
    batchItemUrl = "/admin/batches/item/overflow/";
    batchItemPostbacksUrl = "/admin/batches/item/overflow/";
  }

  const columns = [
    {
      title: "Click ID",
      field: "click_id"
    },
    { title: "Postback URL", field: "postback_url" },
    { title: "Attempts", field: "attempts" },
    { title: "Duration (secs)", field: "duration" },
    { title: "HTTP Status Code", field: "status_code" },
    { title: "Success?", field: "success", formatter: "tickCross" }
  ];

  useEffect(() => {
    api_request(batchItemUrl.concat(props.batchItemId), "GET").then(res => {
      setBatchItem(res.data);
      fetchItems();
    });
  }, []);

  function fetchItems() {
    api_request(batchItemPostbacksUrl.concat(props.batchItemId), "GET").then(
      res => {
        const items = res.data;
        let itemsData = [];
        let failures = 0;

        for (let key in items) {
          const item = items[key];

          itemsData.push({
            id: item.id,
            click_id: item.click_id,
            postback_url: item.postback_url,
            attempts: item.attempts,
            duration: item.duration,
            status_code: item.last_status_code,
            success: item.last_status_code === 200
          });

          if (item.last_status_code !== 200) {
            failures = failures + 1;
          }
        }

        setHowManyToRetry(failures);
        setTableData(itemsData);
      }
    );
  }

  function handleRetry() {
    setIsRetrying(true);
    setTableData(null);
    api_request(
      "/admin/batches/item/postbacks/retry/".concat(props.batchItemId),
      "GET"
    ).then(res => {
      setIsRetrying(false);
      fetchItems();
    });
  }

  return (
    <>
      {batchItem ? (
        <Breadcrumbs
          crumbs={{
            Batches: "/batches",
            Batch: "/batches/".concat(batchItem.batch_id)
          }}
          title={
            props.isOverflow
              ? "Batch Item (overflow)"
              : "Batch Item (conversions)"
          }
        />
      ) : (
        <ContentLoader />
      )}

      <div className="box">
        <h4 className="title is-5">Batch Item Details</h4>
        {batchItem === null ? (
          <ContentLoader />
        ) : (
          <div className="columns">
            <div className="column is-narrow">
              <p>
                <b>Campaign ID:</b> {batchItem.campaign_id}
              </p>
              <p>
                <b>Tag:</b> {batchItem.tag}
              </p>
              <p>
                <b>Date:</b> {batchItem.date}
              </p>
            </div>
            <div className="column is-narrow">
              <p>
                <b>Conversions :</b> {batchItem.conversions}
              </p>
              <p>
                <b>Received:</b>{" "}
                {formatMoney(batchItem.currency, batchItem.received_total)} (
                {formatMoney(batchItem.currency, batchItem.received_conversion)}{" "}
                per conversion)
              </p>
              <p>
                <b>Payout:</b>{" "}
                {formatMoney(batchItem.currency, batchItem.payout_total)} (
                {formatMoney(batchItem.currency, batchItem.payout_conversion)}{" "}
                per conversion)
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="box">
        <h4 className="title is-5">Batch Item Postbacks</h4>
        {howManyToRetry ? (
          <div className="buttons is-right">
            <div
              onClick={handleRetry}
              className={
                "button is-primary " + (isRetrying ? "is-loading" : null)
              }
            >
              Retry All Failed Postbacks ({howManyToRetry})
            </div>
          </div>
        ) : null}
        {tableData === null ? (
          <ContentLoader />
        ) : tableData.length > 0 ? (
          <ReactTabulator
            data={tableData}
            columns={columns}
            tooltips={true}
            options={{ layout: "fitColumns" }}
          />
        ) : (
          "No batch items to display."
        )}
      </div>
    </>
  );
};

export default BatchItem;
