import React from "react";

const Loading = () => {
  return (
    <div id="loading">
      <div id="loading-center">
        <p id="loading-label">azul</p>
        <progress className="progress is-large is-info" max="100"></progress>
      </div>
    </div>
  );
};

export default Loading;
