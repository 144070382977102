import React, { useEffect, useState } from "react";
import Breadcrumbs from "./Breadcrumbs";
import { api_request } from "./Helpers";
import { Link } from "@reach/router";

const Users = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    api_request("/admin/users", "GET").then(res => {
      const users = res.data.users;
      setUsers(users);
    });
  }, []);

  return (
    <>
      <Breadcrumbs title="Users" />
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>User</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, key) => {
            return (
              <tr key={key}>
                <td>{user.name}</td>
                <td>
                  <Link to={"".concat("/users/", user.id, "/changelog")}>
                    Changelog
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Users;
