import React, { useEffect, useState } from "react";
import Breadcrumbs from "./Breadcrumbs";
import { api_request } from "./Helpers";
import Changelog from "./Changelog";
import ContentLoader from "./ContentLoader";

const UserChangelog = props => {
  const [logs, setLogs] = useState(null);
  useEffect(() => {
    api_request("/admin/changelog/user/".concat(props.userId), "GET").then(
      res => {
        const logData = res.data.changelog;

        if (logData.length > 0) {
          setLogs(logData);
        }
      }
    );
  }, []);

  return (
    <>
      <Breadcrumbs
        crumbs={{
          Users: "/users"
        }}
        title="User Changelog"
      />
      {logs === null ? (
        "No logs available."
      ) : logs.length > 0 ? (
        <Changelog logs={logs} user={true} />
      ) : (
        <ContentLoader />
      )}
    </>
  );
};

export default UserChangelog;
