import React, { useEffect, useState } from "react";
import { api_request } from "./Helpers";
import Breadcrumbs from "./Breadcrumbs";
import ContentLoader from "./ContentLoader";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const Affiliate = props => {
  const [affiliate, setAffiliate] = useState(null);

  useEffect(() => {
    api_request("/admin/affiliates/".concat(props.affiliateId), "GET").then(
      res => {
        setAffiliate(res.data.affiliate);
      }
    );
  }, []);

  return (
    <>
      <Breadcrumbs
        crumbs={{
          Affiliates: "/affiliates"
        }}
        title="Affiliate"
      />
      {affiliate === null ? (
        <ContentLoader />
      ) : (
        <>
          <div className="buttons has-addons is-right">
            <Link to={"/affiliates/edit/".concat(affiliate.id)}>
              <button className="button is-primary">
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faPencilAlt} />
                </span>
                <span>Edit Affiliate</span>
              </button>
            </Link>
          </div>
          <div className="box">
            <div className="columns">
              <div className="column is-narrow">
                <p>
                  <b>Name:</b> {affiliate.name}
                </p>
                <p>
                  <b>Cake ID:</b> {affiliate.cake_id}
                </p>
              </div>
              <div className="column is-narrow">
                <p>
                  <b>Payment Currency:</b> {affiliate.payment_currency}
                </p>
                <p>
                  <b>Payment Setting:</b> {affiliate.payment_setting_name}
                </p>
              </div>
              <div className="column is-narrow">
                <p>
                  <b>Postback URL:</b>{" "}
                  <span className="tag is-light">
                    {affiliate.postback_url ? affiliate.postback_url : "N/A"}
                  </span>
                </p>
              </div>
              <div className="column is-narrow">todo: contacts and address</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Affiliate;
