import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faCheck,
  faEdit,
  faMusic,
  faTimes,
  faTv,
  faVideo
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Link } from "@reach/router";

function ServiceFormatter(props) {
  // Formats a Geo Service to look all nice with an icon and all.
  let service = props.cell.getValue();
  return serviceFormatterJsx(service);
}

function ServiceFormatterNonTable(props) {
  // Formats a Geo Service to look all nice with an icon and all.
  return serviceFormatterJsx(props.service);
}

function CampaignLabelFormatter(props) {
  let label = props.cell.getValue();

  if (!label) {
    label = <i>None</i>;
  }

  return (
    <span className="tag">
      <span>
        <b>{label}</b>
      </span>
    </span>
  );
}

function serviceFormatterJsx(service) {
  let jsx = "";
  switch (service.charAt(0).toUpperCase() + service.slice(1)) {
    case "Prime":
      jsx = (
        <span className="tag is-primary">
          <span className="icon is-small">
            <FontAwesomeIcon icon={faVideo} />
          </span>
          <span>{service}</span>
        </span>
      );
      break;
    case "Music":
      jsx = (
        <span className="tag is-success">
          <span className="icon is-small">
            <FontAwesomeIcon icon={faMusic} />
          </span>
          <span>{service}</span>
        </span>
      );
      break;
    default:
      // Channels - these carry a different service string depending on the channel name!
      jsx = (
        <span className="tag is-warning">
          <span className="icon is-small">
            <FontAwesomeIcon icon={faTv} />
          </span>
          <span>{service}</span>
        </span>
      );
      break;
  }

  return jsx;
}

function ActionsFormatter(props) {
  const cellData = props.cell._cell.row.data;
  const redirectEditTo = "".concat(props.section, "/edit/", cellData.id);
  const redirectViewTo = "".concat(props.section, "/", cellData.id);

  if (props.viewOnly) {
    return (
      <>
        <Link to={redirectViewTo}>
          <FontAwesomeIcon icon={faArrowAltCircleRight} /> View
        </Link>
      </>
    );
  }

  if (props.editOnly) {
    return (
      <>
        <Link to={redirectEditTo}>
          <FontAwesomeIcon icon={faEdit} /> Edit
        </Link>
      </>
    );
  }

  return (
    <>
      <Link to={redirectViewTo}>
        <FontAwesomeIcon icon={faArrowAltCircleRight} /> View
      </Link>
      {" | "}
      <Link to={redirectEditTo}>
        <FontAwesomeIcon icon={faEdit} /> Edit
      </Link>
    </>
  );
}

function BatchStatusFormatter(props) {
  let jsx = "";
  const original_status = props.cell._cell.row.data.status;
  let status = props.cell._cell.row.data.status;
  status = status.charAt(0).toUpperCase() + status.slice(1);
  status = status.split("_").join(" ");

  switch (original_status) {
    case "queued":
    case "processing":
    case "postback_queued":
    case "postback_processing":
      jsx = (
        <>
          <div className="content-loader-alt" /> <span>{status}</span>
        </>
      );
      break;
    case "processed":
      jsx = (
        <>
          <span style={{ color: "green" }}>
            <FontAwesomeIcon icon={faCheck} />
          </span>{" "}
          <span>{status}</span>
        </>
      );
      break;
    case "error":
      jsx = (
        <>
          <span style={{ color: "red" }}>
            <FontAwesomeIcon icon={faTimes} />
          </span>{" "}
          <span>{status}</span>
        </>
      );
      break;
  }

  return jsx;
}

function StampUserFormatter(props) {
  let user = props.cell._cell.row.data.stamp_user;
  return <>{user.microsoft_name}</>;
}

function BatchCampaignLinkFormatter(props) {
  let campaign_id = props.cell._cell.row.data.campaign_id;

  return <Link to={"/campaigns/".concat(campaign_id)}>{campaign_id}</Link>;
}

export {
  ServiceFormatter,
  ActionsFormatter,
  ServiceFormatterNonTable,
  BatchStatusFormatter,
  StampUserFormatter,
  BatchCampaignLinkFormatter,
  CampaignLabelFormatter
};
