import Breadcrumbs from "./Breadcrumbs";
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faUpload } from "@fortawesome/free-solid-svg-icons";
import { api_request } from "./Helpers";
import Select from "react-select";
import FormValidationMessage from "./FormValidationMessage";
import { GeoOptionFormatter } from "./Helpers/SelectFormatters";
import { errRequired } from "./Helpers/FormValidation";
import { flag } from "country-emoji";
import { reactFormatter, ReactTabulator } from "react-tabulator";
import ContentLoader from "./ContentLoader";
import {
  ActionsFormatter,
  BatchStatusFormatter,
  ServiceFormatter,
  StampUserFormatter
} from "./Helpers/TableFormatters";
import Flatpickr from "react-flatpickr";
import { dateToString } from "./Helpers/Formatters";

const Batches = props => {
  let fileInput = useRef(null);

  // Show the selected file name next to the input.
  const [fileInputName, setFileInputName] = useState("");

  // Dates for the batch list.
  const date_start = new Date();
  date_start.setDate(date_start.getDate() - 10);
  const date_end = new Date();
  const [dates, setDates] = useState([date_start, date_end]);

  const [type, setType] = useState("disabled");
  const [showForm, setShowForm] = useState(false);

  // react-select.
  const [geoOptions, setGeoOptions] = useState([]);
  const [geoSelectValue, setGeoSelectValue] = useState(null);

  // Form Values.
  const [geo, setGeo] = useState("");
  const [showGeoForm, setShowGeoForm] = useState(true);

  // Form validation.
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [geoValidationMsg, setGeoValidationMsg] = useState(null);
  const [fileValidationMsg, setFileValidationMsg] = useState(null);
  const [backendError, setBackendError] = useState("");

  const [tableData, setTableData] = useState([]);

  const [refreshTable, setRefreshTable] = useState(false);

  const columns = [
    { title: "Batch Time", field: "dt_created" },
    { title: "Geo", field: "geo" },
    {
      title: "Service",
      field: "service",
      formatter: reactFormatter(<ServiceFormatter />)
    },
    { title: "Batch Start Date", field: "dt_start" },
    { title: "Batch End Date", field: "dt_end" },
    {
      title: "User",
      field: "stamp_user",
      formatter: reactFormatter(<StampUserFormatter />)
    },
    {
      title: "Status",
      field: "status",
      formatter: reactFormatter(<BatchStatusFormatter />)
    }
  ];

  const conversionColumns = columns.concat({
    title: "",
    field: "actions",
    formatter: reactFormatter(
      <ActionsFormatter section="batches" viewOnly={true} />
    ),
    headerSort: false
  });

  const overflowColumns = columns.concat({
    title: "",
    field: "actions",
    formatter: reactFormatter(
      <ActionsFormatter section="batches/overflow" viewOnly={true} />
    ),
    headerSort: false
  });

  const options = {
    layout: "fitColumns",
    pagination: "local",
    paginationSize: 20
  };

  useEffect(() => {
    if (!refreshTable) {
      return;
    }

    // Frequently refresh table data.
    const interval = setInterval(() => {
      fetchBatches(type, dates[0], dates[1]);
    }, 7500);
    return () => clearInterval(interval);
  }, [refreshTable]);

  function fetchBatches(typeValue, date_start, date_end) {
    setRefreshTable(false);
    setTableData(null);

    let endpoint = "/admin/batches".concat("/", typeValue);

    if (typeValue === "overflow") {
      endpoint = "/admin/batches/overflow";
    }

    date_start = dateToString(date_start);
    date_end = dateToString(date_end);

    api_request(endpoint.concat("/", date_start, "/", date_end), "GET").then(
      res => {
        buildTable(res.data.batches);
        setRefreshTable(false);
      }
    );
  }

  function buildTable(batches) {
    let batchesData = [];

    if (batches.length === 0) {
      setTableData([]);
      return;
    }

    for (let key in batches) {
      const batch = batches[key];

      let service = "channels"; // Default value for overflow.

      if (batch.channel) {
        service = "".concat(batch.service, " (", batch.channel, ")");
      } else if (batch.service) {
        service = batch.service;
      }

      batchesData.push({
        id: batch.id,
        dt_created: batch.dt_created,
        geo: batch.country_code
          ? "".concat(flag(batch.country_code), " ", batch.country_name)
          : "...",
        service: service,
        dt_start: batch.dt_start ? batch.dt_start : "...",
        dt_end: batch.dt_end ? batch.dt_end : "...",
        status: batch.status,
        stamp_user: {
          microsoft_name: batch.microsoft_name
        }
      });
    }

    setTableData(batchesData);
  }

  function buildGeoOptions(geos, isOverflow = false) {
    let geoOptionsAjax = [];

    for (let key in geos) {
      const geo = geos[key];
      const option = GeoOptionFormatter(geo, isOverflow);
      geoOptionsAjax.push(option);
    }

    return geoOptionsAjax;
  }

  function handleTypeChange(typeValue) {
    setShowForm(null);
    setType(typeValue);
    setTableData(null);
    setShowGeoForm(false);

    // Fetch geos as well as the batch list.
    if (typeValue === "conversions") {
      api_request("/admin/geos", "GET").then(res => {
        let geoOptions = buildGeoOptions(res.data.geos);
        setGeoOptions(geoOptions);
        setShowForm(true);
        setShowGeoForm(true);
        fetchBatches(typeValue, dates[0], dates[1]);
      });
    } else if (typeValue === "overflow") {
      api_request("/admin/geos/channels", "GET").then(res => {
        let geoOptions = buildGeoOptions(res.data.geos, true);
        setGeoOptions(geoOptions);
        setShowForm(true);
        fetchBatches(typeValue, dates[0], dates[1]);
      });
    } else {
      setShowForm(true);
      fetchBatches(typeValue, dates[0], dates[1]);
    }
  }

  function fileInputChange(e) {
    setFileInputName(e.target.files[0].name);
    fileInput.current = e.target.files[0];
  }

  function handleGeoChange(selectedGeo) {
    setGeo(selectedGeo.value);
    setGeoSelectValue(selectedGeo);
  }

  function submit() {
    // Form submission.
    setIsFormSubmitted(true);
    setBackendError("");

    let failedValidation = false;

    setGeoValidationMsg("");
    setFileValidationMsg("");

    if (geo === "" && type === "conversions") {
      setGeoValidationMsg(errRequired);
      failedValidation = true;
    }

    if (fileInput.current === null) {
      setFileValidationMsg(errRequired);
      failedValidation = true;
    }

    if (failedValidation) {
      setIsFormSubmitted(false);
      return;
    }

    let params = {
      file: fileInput.current
    };

    params.type = type;
    params.geo = geo;

    let endpoint = "/admin/batches";
    if (type === "channels") {
      endpoint = "/admin/batches/channels";
    }

    api_request(endpoint, "POST", params, fileInput).then(res => {
      let data = res.data;
      if ("error" in data) {
        setIsFormSubmitted(false);
        setBackendError(data["error"]);
        return;
      }

      afterBatchPost();
    });
  }

  function afterBatchPost() {
    // Reset all form state.
    setIsFormSubmitted(false);
    setGeoSelectValue(null);
    setGeo("");
    setFileInputName("");
    fileInput.current = null;

    fetchBatches(type, dates[0], dates[1]);
  }

  function applyDateFilters(dates) {
    // The date picker submits even when just a single date is selected...
    if (!dates || dates.length < 2) {
      return;
    }

    setDates(dates);

    fetchBatches(type, dates[0], dates[1]);
  }

  return (
    <>
      <Breadcrumbs title="Batches" />
      {props.location.state && props.location.state.deletedBatch ? (
        <div className="notification is-info">Batch has been deleted.</div>
      ) : null}
      <div className="box">
        <form
          encType="multipart/form-data"
          onSubmit={e => {
            e.preventDefault();
            submit();
          }}
        >
          <div className="field">
            <label className="label">Type</label>
            <div className="control">
              <div className="field-body">
                <div className="select">
                  <select
                    onChange={e => handleTypeChange(e.target.value)}
                    value={type}
                  >
                    <option disabled value="disabled">
                      Select a batch type
                    </option>
                    <option value="conversions">Prime</option>
                    <option value="channels">Channels</option>
                    <option value="overflow">Overflow</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {showForm ? (
            <>
              {showGeoForm ? (
                <div className="field">
                  <label className="label">Geo</label>
                  <div className="control">
                    <div className="field-body">
                      <div className="field">
                        <Select
                          isClearable={true}
                          options={geoOptions}
                          value={geoSelectValue}
                          onChange={handleGeoChange}
                        />
                        <FormValidationMessage message={geoValidationMsg} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="field">
                <label className="label">CSV File</label>
                <div className="control">
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            accept=".csv"
                            onChange={fileInputChange}
                            onClick={e => {
                              e.target.value = null; // This allows for the same file to be selected after submission.
                            }}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <FontAwesomeIcon icon={faUpload} />
                            </span>
                            <span className="file-label">Choose a file…</span>
                          </span>
                          {fileInputName ? (
                            <span className="file-name">{fileInputName}</span>
                          ) : null}
                        </label>
                      </div>
                      <FormValidationMessage message={fileValidationMsg} />
                      <FormValidationMessage message={backendError} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="notification is-warning">
                {type === "channels"
                  ? "Channel uploads MUST be CSV files that come directly from Amazon. These files do not require any formatting."
                  : ""}
                {type === "conversions"
                  ? "Prime uploads MUST be CSV files that are pre-formatted."
                  : ""}
                {type === "overflow"
                  ? "Overflow uploads MUST be CSV files that are pre-formatted. You no longer need to split the files by Geo."
                  : ""}
              </div>
              <div className="control">
                <button
                  className={
                    "button is-primary " + (isFormSubmitted ? "is-loading" : "")
                  }
                >
                  Submit
                </button>
              </div>
            </>
          ) : showForm === null ? (
            <ContentLoader />
          ) : null}
        </form>
      </div>
      {type !== "disabled" ? (
        <div className="box">
          <div className="columns">
            <div className="column ">
              <div className="field">
                <label className="label">Batch Dates</label>
                <p className="control has-icons-left">
                  <Flatpickr
                    className="input"
                    value={dates}
                    options={{
                      mode: "range"
                    }}
                    onChange={applyDateFilters}
                  />
                  <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faCalendar} />
                  </span>
                </p>
              </div>
            </div>
          </div>
          {tableData === null ? (
            <ContentLoader />
          ) : tableData.length > 0 ? (
            <>
              <ReactTabulator
                data={tableData}
                columns={
                  type === "conversions" || type === "channels"
                    ? conversionColumns
                    : overflowColumns
                }
                tooltips={true}
                options={options}
              />
            </>
          ) : (
            "No batches to display."
          )}
        </div>
      ) : null}
    </>
  );
};

export default Batches;
