import React from "react";

const DevNote = props => {
  return (
    <>
      <div className="notification">
        <b>Dev Note:</b> {props.note}
      </div>
    </>
  );
};

export default DevNote;
