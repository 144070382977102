import React from "react";

const ContentLoader = () => {
  return (
    <div>
      <div className="content-loader" /> Loading...
    </div>
  );
};

export default ContentLoader;
