import React from "react";
import GeosAddEditForm from "./GeosAddEditForm";
import Breadcrumbs from "./Breadcrumbs";

const GeosEdit = props => {
  return (
    <>
      <Breadcrumbs
        crumbs={{
          Geos: "/geos"
        }}
        title="Edit Geo"
      />
      <GeosAddEditForm geoId={props.geoId} />
    </>
  );
};

export default GeosEdit;
