import React from "react";
import { capitalizeFirstLetter } from "./Helpers/Formatters";
import { Link } from "@reach/router";

const Changelog = props => {
  // user=true means we are showing the full logs for the given user.

  function sectionLink(section, id) {
    let url = "";
    switch (section) {
      case "campaign":
        url = "".concat("/campaigns/", id);
        break;
      case "geo":
        url = "".concat("/geos/", id);
        break;
      case "affiliate":
        url = "".concat("/affiliates/", id);
        break;
      default:
        return section;
    }

    return <Link to={url}> {capitalizeFirstLetter(section)}</Link>;
  }

  return (
    <table className="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>Date</th>
          {props.user ? <th>Section</th> : <th>User</th>}
          <th>Log</th>
        </tr>
      </thead>
      <tbody>
        {props.logs.map((log, key) => {
          return (
            <tr key={key}>
              <td>{log.date}</td>
              {props.user ? (
                <td>{sectionLink(log.section, log.reference_id)}</td>
              ) : (
                <td>
                  {log.username ? (
                    <>
                      {log.username}
                    </>
                  ) : (
                    <span className="tag is-primary">System</span>
                  )}
                </td>
              )}

              <td>{log.log_message}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Changelog;
