import React, { useEffect, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { api_request } from "./Helpers";
import { GeoOptionFormatter } from "./Helpers/SelectFormatters";
import ContentLoader from "./ContentLoader";
import { dateToString } from "./Helpers/Formatters";

const ReportingForm = props => {
  const [showForm, setShowForm] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  // Ajax values.
  const [currencies, setCurrencies] = useState([]);
  const [geoOptions, setGeoOptions] = useState([]);
  const [affiliateOptions, setAffiliateOptions] = useState([]);
  const [accountManagerOptions, setAccountManagerOptions] = useState([]);
  const [salesPersonOptions, setSalesPersonOptions] = useState([]);

  // React-select values.
  const [geoSelectValue, setGeoSelectValue] = useState(null);
  const [affiliateSelectValue, setAffiliateSelectValue] = useState(null);
  const [accountManagerSelectValue, setAccountManagerSelectValue] = useState(
    null
  );
  const [salesPersonSelectValue, setSalesPersonSelectValue] = useState(null);

  // Load form state from localStorage or default values.
  const geoId = useRef(
    localStorage.getItem("reportsGeoId")
      ? parseInt(localStorage.getItem("reportsGeoId"))
      : 0
  );
  const affiliateId = useRef(
    localStorage.getItem("reportsAffiliateId")
      ? parseInt(localStorage.getItem("reportsAffiliateId"))
      : 0
  );
  const accountManagerId = useRef(
    localStorage.getItem("reportsAccountManagerId")
      ? parseInt(localStorage.getItem("reportsAccountManagerId"))
      : 0
  );
  const salesPersonId = useRef(
    localStorage.getItem("reportsSalesPersonId")
      ? parseInt(localStorage.getItem("reportsSalesPersonId"))
      : 0
  );

  const [dates, setDates] = useState(
    localStorage.getItem("reportsDateStart")
      ? [
          new Date(localStorage.getItem("reportsDateStart")),
          new Date(localStorage.getItem("reportsDateEnd"))
        ]
      : [new Date(), new Date()]
  );

  const [currency, setCurrency] = useState(
    localStorage.getItem("reportsCurrency")
      ? localStorage.getItem("reportsCurrency")
      : "GBP"
  );

  const [overflowAgg, setOverflowAgg] = useState(
    localStorage.getItem("reportsOverflowAgg")
      ? localStorage.getItem("reportsOverflowAgg") === "true" // Actual bool conversion.
      : true
  );

  // Tag labels.
  const [datesTag, setDatesTag] = useState("");
  const [currencyTag, setCurrencyTag] = useState("");
  const [geoTag, setGeoTag] = useState("");
  const [affiliateTag, setAffiliateTag] = useState("");
  const [accountManagerTag, setAccountManagerTag] = useState("");
  const [salesPersonTag, setSalesPersonTag] = useState("");

  useEffect(() => {
    ajaxCalls().then(res => {
      setShowForm(true);
      submitForm();
    });
  }, []);

  function handleShowFilters() {
    setShowFilters(!showFilters);
  }

  const submitForm = async () => {
    setIsFormSubmitted(true);

    // Format dates into DD-MM-YYYY
    let dateStart = dateToString(dates[0]);
    let dateEnd = dateToString(dates[1]);

    // Set the display tags to inform users what current filters are set.
    setDatesTag("".concat(dateStart, " to ", dateEnd));
    setCurrencyTag(currency);

    // Save form details in localStorage.
    localStorage.setItem("reportsDateStart", dates[0]);
    localStorage.setItem("reportsDateEnd", dates[1]);
    localStorage.setItem("reportsCurrency", currency);
    localStorage.setItem("reportsOverflowAgg", overflowAgg);

    if (geoId.current) {
      localStorage.setItem("reportsGeoId", geoId.current);
      if (geoSelectValue) {
        setGeoTag(geoSelectValue.label);
      }
    } else {
      setGeoTag("");
      setGeoSelectValue(null);
      localStorage.removeItem("reportsGeoId");
    }

    if (affiliateId.current) {
      localStorage.setItem("reportsAffiliateId", affiliateId.current);
      if (affiliateSelectValue) {
        setAffiliateTag(affiliateSelectValue.label);
      }
    } else {
      setAffiliateTag("");
      setAffiliateSelectValue(null);
      localStorage.removeItem("reportsAffiliateId");
    }

    if (accountManagerId.current) {
      localStorage.setItem("reportsAccountManagerId", accountManagerId.current);
      if (accountManagerSelectValue) {
        setAccountManagerTag(accountManagerSelectValue.label);
      }
    } else {
      setAccountManagerTag("");
      setAccountManagerSelectValue(null);
      localStorage.removeItem("reportsAccountManagerId");
    }

    if (salesPersonId.current) {
      localStorage.setItem("reportsSalesPersonId", salesPersonId.current);
      if (salesPersonSelectValue) {
        setSalesPersonTag(salesPersonSelectValue.label);
      }
    } else {
      setSalesPersonTag("");
      setSalesPersonSelectValue(null);
      localStorage.removeItem("reportsSalesPersonId");
    }

    switch (props.section) {
      case "geos":
        await props.tallyCallback(
          dateStart,
          dateEnd,
          currency,
          overflowAgg,
          accountManagerId.current,
          salesPersonId.current
        );
        break;
      case "affiliates":
        await props.tallyCallback(
          dateStart,
          dateEnd,
          currency,
          overflowAgg,
          geoId.current,
          accountManagerId.current,
          salesPersonId.current
        );
        break;
      case "campaigns":
        await props.tallyCallback(
          dateStart,
          dateEnd,
          currency,
          overflowAgg,
          geoId.current,
          affiliateId.current,
          accountManagerId.current,
          salesPersonId.current
        );
        break;
    }

    setIsFormSubmitted(false);
  };

  // Depending on what section is loaded, different AJAX calls are required for the filters form.
  async function ajaxCalls() {
    switch (props.section) {
      case "geos":
        return await Promise.all([
          ajaxCurrencies(),
          ajaxAccountManagers(),
          ajaxSalesPeople()
        ]);
      case "affiliates":
        return await Promise.all([
          ajaxCurrencies(),
          ajaxAccountManagers(),
          ajaxGeos(),
          ajaxSalesPeople()
        ]);
      case "campaigns":
        return await Promise.all([
          ajaxCurrencies(),
          ajaxAccountManagers(),
          ajaxGeos(),
          ajaxAffiliates(),
          ajaxSalesPeople()
        ]);
    }
  }

  async function ajaxCurrencies() {
    api_request("/admin/currencies", "GET").then(res => {
      setCurrencies(res.data.currencies);
    });
  }

  async function ajaxGeos() {
    api_request("/admin/geos", "GET").then(res => {
      let geosAjax = res.data.geos;
      let geosAjaxDict = {};
      let geoOptionsAjax = [];

      for (let key in geosAjax) {
        const geo = geosAjax[key];

        const option = GeoOptionFormatter(geo);

        // If a geo was previously stored.
        if (option.value === parseInt(geoId.current)) {
          setGeoSelectValue(option);
          setGeoTag(option.label);
        }

        geoOptionsAjax.push(option);
        geosAjaxDict[geo.id] = geo;
      }
      setGeoOptions(geoOptionsAjax);
    });
  }

  // TODO: format code to be similar to above ajaxGeos.
  async function ajaxAffiliates() {
    api_request("/admin/affiliates", "GET").then(res => {
      let affiliatesAjax = res.data.affiliates;
      let affiliatesSel = [];
      let affiliatesSelDict = {};

      for (let key in affiliatesAjax) {
        const affiliate = affiliatesAjax[key];

        // TODO: Create formatter for this and update other sections of the code that are duplicate.
        const option = {
          value: affiliate.id,
          label: "".concat("(#", affiliate.cake_id, ") ", affiliate.name)
        };

        if (option.value === parseInt(affiliateId.current)) {
          setAffiliateSelectValue(option);
          setAffiliateTag(option.label);
        }

        affiliatesSel.push(option);
        affiliatesSelDict[affiliate.id] = option;
      }
      setAffiliateOptions(affiliatesSel);
    });
  }

  async function ajaxAccountManagers() {
    api_request("/admin/affiliates/account-managers", "GET").then(res => {
      let accountManagersAjax = res.data.account_managers;
      let accountManagersAjaxDict = {};
      let accountManagerOptionsAjax = [];

      for (let key in accountManagersAjax) {
        const accountManager = accountManagersAjax[key];

        const option = {
          value: accountManager.id,
          label: accountManager.account_manager
        };

        if (option.value === parseInt(accountManagerId.current)) {
          setAccountManagerSelectValue(option);
          setAccountManagerTag(option.label);
        }

        accountManagerOptionsAjax.push(option);
        accountManagersAjaxDict[accountManager.id] = accountManager;
      }
      setAccountManagerOptions(accountManagerOptionsAjax);
    });
  }

  async function ajaxSalesPeople() {
    api_request("/admin/users/sales", "GET").then(res => {
      let salesPeopleAjax = res.data.users;
      let salesPeopleAjaxDict = {};
      let salesPersonOptionsAjax = [];

      for (let key in salesPeopleAjax) {
        const salesPerson = salesPeopleAjax[key];

        const option = {
          value: salesPerson.id,
          label: salesPerson.name
        };

        if (option.value === parseInt(salesPersonId.current)) {
          setSalesPersonSelectValue(option);
          setSalesPersonTag(option.label);
        }

        salesPersonOptionsAjax.push(option);
        salesPeopleAjaxDict[salesPerson.id] = salesPerson;
      }
      setSalesPersonOptions(salesPersonOptionsAjax);
    });
  }

  function handleGeoChange(selectedGeo) {
    if (!selectedGeo) {
      geoId.current = 0;
      setGeoSelectValue(null);
      return;
    }

    geoId.current = parseInt(selectedGeo.value);
    setGeoSelectValue(selectedGeo);
  }

  function handleAffiliateChange(selectedAffiliate) {
    if (!selectedAffiliate) {
      affiliateId.current = 0;
      setAffiliateSelectValue(null);
      return;
    }

    affiliateId.current = parseInt(selectedAffiliate.value);
    setAffiliateSelectValue(selectedAffiliate);
  }

  function handleAccountManagerChange(selectedAccountManager) {
    if (!selectedAccountManager) {
      accountManagerId.current = 0;
      setAccountManagerSelectValue(null);
      return;
    }

    accountManagerId.current = parseInt(selectedAccountManager.value);
    setAccountManagerSelectValue(selectedAccountManager);
  }

  function handleSalesPersonChange(selectedSalesPerson) {
    if (!selectedSalesPerson) {
      salesPersonId.current = 0;
      setSalesPersonSelectValue(null);
      return;
    }

    salesPersonId.current = parseInt(selectedSalesPerson.value);
    setSalesPersonSelectValue(selectedSalesPerson);
  }

  function handleGeoTagReset() {
    geoId.current = 0;
    submitForm();
  }

  function handleAffiliateTagReset() {
    affiliateId.current = 0;
    submitForm();
  }

  function handleAccountManagerReset() {
    accountManagerId.current = 0;
    submitForm();
  }

  function handleSalesPersonReset() {
    salesPersonId.current = 0;
    submitForm();
  }

  function resetFilters() {
    geoId.current = 0;
    affiliateId.current = 0;
    accountManagerId.current = 0;
    salesPersonId.current = 0;
    submitForm();
  }

  return (
    <>
      {showForm ? (
        <>
          {datesTag ? (
            <div className="field is-grouped is-grouped-multiline">
              <div className="control">
                <div className="tags">
                  <span className="tag">
                    <b>Dates:</b>&nbsp; {datesTag}
                  </span>
                </div>
              </div>

              <div className="control">
                <div className="tags">
                  <span className="tag">
                    <b>Currency:</b>&nbsp; {currencyTag}
                  </span>
                </div>
              </div>

              {geoTag ? (
                <div className="control">
                  <div className="tags has-addons">
                    <span className="tag is-link">
                      <b>Geo:</b>&nbsp; {geoTag}
                    </span>
                    <a onClick={handleGeoTagReset} className="tag is-delete" />
                  </div>
                </div>
              ) : null}

              {affiliateTag ? (
                <div className="control">
                  <div className="tags has-addons">
                    <span className="tag is-link">
                      <b>Affiliate:</b>&nbsp; {affiliateTag}
                    </span>
                    <a
                      onClick={handleAffiliateTagReset}
                      className="tag is-delete"
                    />
                  </div>
                </div>
              ) : null}

              {accountManagerTag ? (
                <div className="control">
                  <div className="tags has-addons">
                    <span className="tag is-link">
                      <b>Account Manager:</b>&nbsp; {accountManagerTag}
                    </span>
                    <a
                      onClick={handleAccountManagerReset}
                      className="tag is-delete"
                    />
                  </div>
                </div>
              ) : null}

              {salesPersonTag ? (
                <div className="control">
                  <div className="tags has-addons">
                    <span className="tag is-link">
                      <b>Sales Person:</b>&nbsp; {salesPersonTag}
                    </span>
                    <a
                      onClick={handleSalesPersonReset}
                      className="tag is-delete"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          <nav className="panel">
            <p
              className="panel-heading no-user-select"
              style={{ cursor: "pointer" }}
              onClick={handleShowFilters}
            >
              <span className="icon is-small">
                <FontAwesomeIcon
                  icon={showFilters ? faArrowCircleUp : faArrowCircleDown}
                />
              </span>{" "}
              Filters
            </p>
          </nav>
          {showFilters ? (
            <>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Date Range</label>
                    <p className="control has-icons-left">
                      <Flatpickr
                        className="input"
                        value={dates}
                        options={{
                          mode: "range"
                        }}
                        onChange={e => setDates(e)}
                      />
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCalendar} />
                      </span>
                    </p>
                  </div>
                  {Object.keys(geoOptions).length ? (
                    <div className="field">
                      <label className="label">Geo</label>
                      <Select
                        isClearable={true}
                        options={geoOptions}
                        onChange={handleGeoChange}
                        value={geoSelectValue}
                      />
                    </div>
                  ) : null}

                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={overflowAgg}
                      onChange={e => setOverflowAgg(e.target.checked)}
                    />{" "}
                    Combine Overflow figures?
                  </label>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Currency</label>
                    <p className="control is-expanded">
                      <span className="select is-fullwidth">
                        <select
                          value={currency}
                          onChange={e => setCurrency(e.target.value)}
                        >
                          {currencies.map(function(currency, i) {
                            return (
                              <option key={i} value={currency.iso_code}>
                                {currency.name}
                              </option>
                            );
                          })}
                        </select>
                      </span>
                    </p>
                  </div>
                  {Object.keys(affiliateOptions).length ? (
                    <div className="field">
                      <label className="label">Affiliate</label>
                      <Select
                        isClearable={true}
                        options={affiliateOptions}
                        onChange={handleAffiliateChange}
                        value={affiliateSelectValue}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="column">
                  {Object.keys(accountManagerOptions).length ? (
                    <div className="field">
                      <label className="label">Account Manager</label>
                      <Select
                        isClearable={true}
                        options={accountManagerOptions}
                        onChange={handleAccountManagerChange}
                        value={accountManagerSelectValue}
                      />
                    </div>
                  ) : null}
                  {Object.keys(salesPersonOptions).length ? (
                    <div className="field">
                      <label className="label">Sales Person</label>
                      <Select
                        isClearable={true}
                        options={salesPersonOptions}
                        onChange={handleSalesPersonChange}
                        value={salesPersonSelectValue}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <button
                    className={
                      "button is-primary is-small is-fullwidth " +
                      (isFormSubmitted ? "is-loading" : "")
                    }
                    onClick={submitForm}
                  >
                    Apply filters
                  </button>
                </div>
                <div className="column">
                  <button
                    onClick={resetFilters}
                    className="button is-link is-outlined is-fullwidth"
                  >
                    Reset all filters
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <ContentLoader />
      )}
    </>
  );
};

export default ReportingForm;
