import React, { useEffect, useState } from "react";
import { api_request } from "./Helpers";
import ContentLoader from "./ContentLoader";
import Geo from "./Geo";
import GeoTabs from "./GeoTabs";
import Changelog from "./Changelog";

const GeoChangelog = props => {
  const [logs, setLogs] = useState(null);
  useEffect(() => {
    api_request("".concat("/admin/changelog/geo/", props.geoId), "GET").then(
      res => {
        const logData = res.data.changelog;

        if (logData.length > 0) {
          setLogs(logData);
        }
      }
    );
  }, []);

  return (
    <>
      <Geo geoId={props.geoId} />
      <GeoTabs geoId={props.geoId} active="changelog" />

      {logs === null ? (
        "No logs available."
      ) : logs.length > 0 ? (
        <Changelog logs={logs} />
      ) : (
        <ContentLoader />
      )}
    </>
  );
};

export default GeoChangelog;
