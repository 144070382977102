import React, { useEffect, useState } from "react";
import { api_request } from "./Helpers";
import Breadcrumbs from "./Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPlus } from "@fortawesome/free-solid-svg-icons";
import FormValidationMessage from "./FormValidationMessage";
import { navigate } from "@reach/router";
import Select from "react-select";

const AffiliatesEdit = props => {
  const [affiliateName, setAffiliateName] = useState("");
  const [cakeAffiliateId, setCakeAffiliateId] = useState(null);
  const [cakeId, setCakeId] = useState(null);
  const [paymentCurrency, setPaymentCurrency] = useState("");
  const [paymentSettingName, setPaymentSettingName] = useState("");
  const [postbackUrl, updatePostbackUrl] = useState("");
  const [notes, updateNotes] = useState("");

  const [salesPersonId, setSalesPersonId] = useState("");
  const [salesPersonOptions, setSalesPersonOptions] = useState([]);
  const [salesPersonSelectValue, setSalesPersonSelectValue] = useState(null);

  const [showForm, setShowForm] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [backendError, setBackendError] = useState(null);

  useEffect(() => {
    api_request("/admin/affiliates/" + props.affiliateId, "GET").then(res => {
      let affiliate = res.data.affiliate;
      api_request("/admin/users/sales", "GET").then(res => {
        let users = res.data.users;
        let salesPeople = [];

        for (let key in users) {
          const user = users[key];

          const option = {
            value: user.id,
            label: user.name
          };

          if (option.value === parseInt(affiliate.sales_person_stamp_id)) {
            setSalesPersonSelectValue(option);
            setSalesPersonId(parseInt(option.value));
          }

          salesPeople.push(option);
        }

        setAffiliateName(affiliate.name);
        setCakeAffiliateId(affiliate.cake_id);
        setCakeId(affiliate.cake_id);
        setPaymentCurrency(affiliate.payment_currency);
        setPaymentSettingName(affiliate.payment_setting_name);
        updatePostbackUrl(affiliate.postback_url);
        updateNotes(affiliate.notes);

        setSalesPersonOptions(salesPeople);

        setShowForm(true);
      });
    });
  }, []);

  function handleSalesPersonChange(selectedValue) {
    setSalesPersonId(parseInt(selectedValue.value));
    setSalesPersonSelectValue(selectedValue);
  }

  function submit() {
    setIsFormSubmitted(true);

    let params = {
      cake_id: cakeAffiliateId,
      edit: true
    };

    if (postbackUrl) {
      params.postback_url = postbackUrl;
    }

    if (notes) {
      params.notes = notes;
    }

    if (salesPersonId) {
      params.sales_person_id = salesPersonId;
    }

    api_request("/admin/affiliates", "POST", params).then(res => {
      let data = res.data;
      if ("error" in data) {
        setIsFormSubmitted(false);
        setBackendError(data["error"]);
        return;
      }

      navigate("/affiliates", {
        state: { editAffiliateId: data["affiliate_id"] }
      });
    });
  }

  return (
    <>
      <Breadcrumbs
        crumbs={{
          Affiliates: "/affiliates"
        }}
        title="Edit Affiliate"
      />
      <p>
        <b>Note:</b> Fields in grey need to be edited in CAKE.
      </p>
      <br />
      <form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <input className="input" disabled={true} value={affiliateName} />
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">CAKE ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <input className="input" disabled={true} value={cakeId} />
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Payment Currency</label>
          </div>
          <div className="field-body">
            <div className="field">
              <input
                className="input"
                disabled={true}
                value={paymentCurrency}
              />
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Payment Setting Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <input
                className="input"
                disabled={true}
                value={paymentSettingName}
              />
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Postback URL</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  value={postbackUrl}
                  onChange={e => updatePostbackUrl(e.target.value)}
                  placeholder="https://postback.com?id=[s2]"
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faLink} />
                </span>
                <p className="help">
                  You can use GET parameters (such as s2) from the click URL to
                  pass data back in the Postback URL.
                  <br />
                  If the URL above is set, a postback will always be sent.
                  <br />
                  This URL can be overriden at the campaign level.
                </p>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Sales Person</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Select
                isClearable={true}
                options={salesPersonOptions}
                onChange={handleSalesPersonChange}
                value={salesPersonSelectValue}
              />
              <p className="help">
                Required for bonus purposes. The sales person is often different
                to the account manager.
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Notes</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <textarea
                  className="textarea"
                  value={notes}
                  onChange={e => updateNotes(e.target.value)}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label" />
          <div className="field-body">
            <div className="field">
              <FormValidationMessage message={backendError} />
              <div className="control">
                <button
                  className={
                    "button is-primary " + (isFormSubmitted ? "is-loading" : "")
                  }
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                  <span>Edit Affiliate</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AffiliatesEdit;
